import styled, { css } from 'styled-components'

export const CheckboxWrap = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 0;
  cursor: pointer;
`

export const StyledInput = styled.input`
  position: absolute;
  left: 0;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
`

export const LabelText = styled.span`
  cursor: pointer;
  line-height: 1;
  padding-right: 8px;
`

export const StyledCheckbox = styled.div`
  ${({ checked }) => css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #444444;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border-radius: 3px;
    border: 1px solid ${checked ? '#c1a283' : '#999999'};
    background: #444444;
    color: #c1a283;
    transition: all 0.5s;
    cursor: pointer;
    :hover {
      border-color: #c1a283;
    }
    &:after {
      content: '';
      position: relative;
      border: 1px solid ${({ checked }) => (checked ? '#c1a283' : 'transparent')};
      width: 10px;
      height: 5px;
      transform: rotate(-50deg);
      border-top-color: transparent;
      border-right-color: transparent;
      top: -1px;
    }
  `}
`

export const CheckboxContainer = styled.div`
  display: inline-block;
  > label {
    margin-right: 8px;
  }
`

export const CheckboxBody = styled.div`
  display: ${({ direction }) => (direction === 'column' ? 'block' : 'inline-block')};
  ${({ direction }) =>
    direction &&
    css`
      margin-bottom: 10px;
    `};
`
