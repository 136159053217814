import styled, { css } from 'styled-components'
import { transition, Media } from '../theme'

export const PageContainer = styled.div`
  background: #0f0a07;
  font-family: 'Source Han Sans CN', 'Source Han Sans SC', -apple-system, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  text-rendering: optimizeLegibility;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  padding-top: 70px;
`

export const HeaderContainr = styled.div`
  height: 70px;
  width: 100%;
  background: #2e2f30;
  position: fixed;
  top: 0;
  z-index: 10;
  ${Media.phone`
    padding: 0 10px;
  `}
`

export const HeaderWrapper = styled.div`
  height: 70px;
  max-width: 1400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`

export const HeaderLeftWrapper = styled.div`
  height: 40px;
  width: 151px;
`

export const HeaderRightWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  ${Media.phone`
    > a:not(:last-child) {
      display: none;
      width: 100%;
    }
  `}
`

export const HeaderRightItem = styled.a`
  color: #caa577;
  font-size: 16px;
  width: 160px;
  line-height: 70px;
  cursor: pointer;
  text-align: center;
  display: block;
  transition: ${transition('background')};
  :hover {
    background: #444444;
  }
`

export const HeaderButton = styled.button`
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #caa577;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  border: 0;
  margin-left: 30px;
`

export const BannerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  .mobile-banner-container,
  .pc-banner-container {
    position: absolute;
    width: 100%;
  }
  .pc-banner,
  .pc-banner-container {
    display: block;
    ${Media.phone`
      display: none;
    `}
  }
  .mobile-banner,
  .mobile-banner-container {
    display: none;
    ${Media.phone`
      display: block;
    `}
  }
`

export const BannerContentWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${Media.phone`
    padding: 0 10px;
  `}
  .contest-time {
    height: 19px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 40px;
    text-align: center;
    ${Media.phone`
      margin-top: 80px;
    `}
  }
  .pc-banner {
    margin-top: 211px;
  }
  .mobile-banner {
    margin-top: 181px;
  }
  .second-title {
    margin-top: 32px;
  }
`

export const OrganizerWrapper = styled.div`
  border: 1px solid transparent;
  border-image: linear-gradient(0deg, transparent, #caa577) 10 10;
  background: linear-gradient(0deg, #2e2f30 0%, rgba(68, 68, 68, 0.2) 100%);
  border-bottom: none;
  height: 141px;
  width: 1201px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 146px;
  margin-top: 80px;
  flex-wrap: wrap;
  > div {
    display: flex;
    flex-wrap: nowrap;
    height: 25px;
    gap: 20px;
    align-items: center;
    > p {
      white-space: nowrap;
    }
  }
  ${Media.phone`
    flex-wrap: wrap;
    width: 100%;
    max-width: 600px;
    height: 150px;
    padding: 10px 10px 25px 10px;
    > div {
      display: block;
    }
    > div:nth-child(1) {
      width: 35%;
      margin-right: 10px;
    }
    > div:nth-child(2) {
      width: 60%;
    }
    > div:nth-child(3) {
      width: 95%;
    }
    img {
      width: 100%;
    }
  `}
`

export const IntroductionWrapper = styled.div`
  margin-top: 190px;
  width: 680px;
  height: 640px;
  position: relative;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom, #caa577, #000) 10 10;
  background: linear-gradient(0deg, #2e2f30 0%, rgba(68, 68, 68, 0.3) 100%);
  border-bottom: none;
  ${Media.phone`
    width: 100%;
    max-width: 600px;
    margin-top: 80px;
  `}
`

export const IntroductionTitle = styled.div`
  width: 265px;
  height: 58px;
  position: absolute;
  left: calc((100% - 265px) / 2);
  top: -29px;
  line-height: 58px;
  text-align: center;
  color: #fff;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom, #caa577, transparent) 10 10;
  border-bottom: none;
  backdrop-filter: blur(5px);
  background: linear-gradient(to bottom, rgba(195, 149, 118, 0.8) 0%, rgba(68, 45, 30, 0.1) 60%);
  ${Media.phone`
    width: 150px;
    left: calc((100% - 150px) / 2);
    font-size: 18px;
  `}
`

export const LinearGradientTitle = styled.p`
  font-size: 30px;
  font-weight: bold;
  background: linear-gradient(to bottom, #fff, #caa577);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: ${({ top }) => (top ? top + 'px' : 'unset')};
  text-align: center;
  margin-bottom: ${({ pcBottom }) => pcBottom && pcBottom + 'px'};
  ${Media.phone`
    transform: scale(0.8);
    margin-bottom: ${({ mobileBottom }) => mobileBottom && mobileBottom + 'px'};
  `}
`

export const IntroductionContent = styled.div`
  width: 328px;
  height: 522px;
  margin: 69px auto;
  font-weight: 400;
  color: #fff;
  > p {
    text-align: center;
    font-size: 16px;
    height: 16px;
    margin-bottom: 18px;
    white-space: nowrap;
    i {
      font-size: 18px;
      font-weight: bold;
    }
  }
  ${Media.phone`
    > p {
      font-size: 14px;
      i {
        font-size: 16px;
      }
    }
  `}
`

export const ContestTimeWrapper = styled.div`
  height: 150px;
  width: 1250px;
  position: relative;
  color: #fff;
  margin-top: 105px;
  ${Media.phone`
    width: 100%;
    max-width: 600px;
    height: 80px;
    > p:nth-child(1) {
      margin-bottom: 60px;
    }
  `}
`

export const ContestTimeContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-size: 36px;
  justify-content: space-around;
  ${Media.phone`
    flex-wrap: wrap;
    justify-content: center;
    align-items: unset;
    column-gap: 10px;
    font-size: 16px;
    p {
      height: 0px;
      transform: translateY(-15px);
    }
    > div {
      width: 80px;
      height: 50px;
      line-height: 38px;
      text-align: center;
    }
    > div:last-child {
      transform: translateY(-20px);
    }
  `}
`

export const ContestTimeUpperText = styled.div`
  width: 105px;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  white-space: nowrap;
  > div {
    margin-bottom: 10px;
  }
  > p {
    margin-bottom: 12px;
  }
  ${Media.phone`
    font-size: 14px;
    height: 20px;
  `}
`

export const ContestTimeArrorWrapper = styled(ContestTimeUpperText)`
  ${Media.phone`
    .arrow {
      height: 10px;
    }
    img {
      transform: scale(0.9);
      width: 100%;
    }
  `}
`

export const GroupContestWrapper = styled.div`
  margin-top: 105px;
  > p:nth-child(1) {
    margin-bottom: 60px;
  }
  ${Media.phone`
    margin-top: 200px;
  `}
`

export const GroupContestContentWrapper = styled.div`
  width: 1200px;
  height: 320px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  ${Media.phone`
    width: 100%;
    height: 280px;
    gap: 10px;
  `}
`

export const GroupContestItem = styled.div`
  width: 300px;
  height: 200px;
  border: 1px solid transparent;
  border-image: linear-gradient(0deg, #caa577, #000) 10 10;
  background: linear-gradient(0deg, #2e2f30 0%, rgba(68, 68, 68, 0.3) 100%);
  border-top: none;
  position: relative;
  > section {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 20px;
    left: 20px;
    ${Media.phone`
      left: 20px;
      top: 10px;
      transform: scale(0.7);
    `}
  }
  ${Media.phone`
    width: 120px;
    height: 200px;
  `}
`

export const GroupContestItemContent = styled.div`
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  p {
    text-align: center;
    color: #fff;
  }
  > p:nth-child(1) {
    color: #caa577;
    font-size: 24px;
    ${Media.phone`
      font-size: 16px;
    `}
  }
  p:not(:nth-child(1)) {
    font-size: 16px;
    ${Media.phone`
      font-size: 12px;
    `}
  }
  .group-mobile {
    display: none;
  }
  ${Media.phone`
    .group-pc {
      display: none;
    }
    .group-mobile {
      display: block;
      font-size: 12px;
    }
  `}
`

export const ContestAwardWrapper = styled.div`
  width: 1200px;
  height: 440px;
  border: 1px solid transparent;
  border-image: linear-gradient(0deg, #caa577, #000) 10 10;
  background: linear-gradient(0deg, #2e2f30 0%, rgba(68, 68, 68, 0.3) 100%);
  border-top: none;
  display: flex;
  margin-bottom: 100px;
  ${Media.phone`
    width: 100%;
    display: block;
    height: 100%;
    margin-bottom: 50px;
  `}
`

export const AwardLeftMenuWrapper = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  ${Media.phone`
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    height: 60px;
    margin: 0 auto;
    transform: scale(0.8) translateY(-8px);
  `}
`

export const AwardLeftItem = styled.div`
  width: 200px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  line-height: 40px;
  font-size: ${({ isActive }) => (isActive ? '18px' : '14px')};
  color: ${({ isActive }) => (isActive ? '#caa577' : '#999999')};
  transition: ${transition('all')};
  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid transparent;
      border-image: linear-gradient(90deg, #caa577, transparent) 10 10;
      border-right: none;
      background: linear-gradient(90deg, #2e2f30 0%, transparent 100%);
    `}

  ${Media.phone`
     width: unset;
     height: 61px;
     white-space: nowrap;
     font-size: 12px;
     .pc-text {
       display: none;
     }
     ${({ isActive }) =>
       isActive &&
       css`
         border-image: linear-gradient(to bottom, #caa577, #444444) 10 10;
         background: linear-gradient(to bottom, #2e2f30 0%, rgba(68, 68, 68, 0.3) 100%);
       `}
  `}
`

export const AwardRightContentWrapper = styled.div`
  display: flex;
  color: #fff;
  text-align: center;
  justify-content: space-around;
  gap: 19px;
  margin-left: 20px;
  ${Media.phone`
    display: block;
    margin: 0 auto;
  `}
`

export const AwardItem = styled.div`
  width: ${({ pcWidth }) => pcWidth + 'px'};
  height: ${({ height }) => (height ? height + 'px' : '100%')};
  ${Media.phone`
    width: ${({ mobileWidth }) => mobileWidth};
    margin: 0 auto;
    margin-bottom: 30px;
  `}
`

export const AwardItemTitle = styled.div`
  width: 100%;
  height: ${({ withoutTag }) => (withoutTag ? '60px' : '100px')};
  background: linear-gradient(to bottom, rgba(195, 149, 118, 0.8) 0%, rgba(68, 45, 30, 0) 70%);
  border-image: linear-gradient(0deg, #000, #caa577) 10 10;
  border-left: 1px solid #caa577;
  border-right: 1px solid #caa577;
  border-top: 1px solid #caa577;
  border-bottom: 0;
  > p:nth-child(1) {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    ${Media.phone`
      font-size: 30px;
    `}
  }
  padding-top: 17px;
  margin-bottom: 10px;
  ${Media.phone`
  height: ${({ withoutTag }) => (withoutTag ? '80px' : '150px')};
    margin-bottom: 0px;
  `}
`

export const AardItemTag = styled.div`
  height: 29px;
  line-height: 29px;
  width: 80%;
  background: rgba(202, 165, 119, 0.5);
  color: #fff;
  border-radius: 15px;
  font-weight: 400;
  font-size: 16px;
  margin: 0 auto;
  strong {
    font-size: 18px;
    font-weight: bold;
  }
  ${Media.phone`
    height: 40px;
    border-radius: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    font-size: 20px;
    ${({ mobileMini }) =>
      mobileMini &&
      css`
        width: 100%;
        font-size: 12px;
        padding: 0 5px;
      `}
  `}
`

export const AwardDetail = styled.div`
  height: calc((100% - 100px) / 2);
  display: flex;
  flex-direction: column;
  p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    white-space: nowrap;
  }
  .is-pc {
    display: block;
  }
  .is-mobile {
    display: none;
  }
  ${Media.phone`
    p {
      font-size: 12px;
    }
    .is-pc {
      display: none;
    }
    .is-mobile {
      display: inline;
    }
  `}
`

export const MainJudgeWrapper = styled.div`
  width: 1201px;
  display: flex;
  column-gap: 20px;
  > section {
    margin-right: 34px;
    ${Media.phone`
        width: 100%;
        margin: 0;
        p {
          width: 100%;
          font-size: 20px;
          white-space: nowrap;
          text-align: center;
        }
        margin-bottom: 30px;
    `}
  }
  p {
    width: 90px;
    color: #caa577;
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    white-space: pre-wrap;
    text-align: left;
  }
  ${Media.phone`
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: ${({ mobileBottom }) => mobileBottom + 'px'};
  `}
  margin-bottom: ${({ pcBottom }) => pcBottom + 'px'};
`

export const PeoplePictureWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: ${({ main }) => (main ? 'wrap' : 'nowrap')};
  column-gap: 20px;
  row-gap: 80px;
  > div {
    width: ${({ main }) => (main ? '310px' : '250px')};
    height: ${({ main }) => (main ? '300px' : '248px')};
  }
  ${Media.phone`
    flex-wrap: wrap;
    padding-left: 10px;
    > div {
      width: 45%;
      height: auto;
    }
  `}
`

export const VideoWrapper = styled.div`
  width: 1200px;
  height: 335px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 80px;
  .video-mobile-text {
    display: none;
  }
  ${Media.phone`
    margin-bottom: 180px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    .video-pc-text {
      display: none;
    }
    .video-mobile-text {
      display: block;
    }
  `}
`

export const LeftVideoContainer = styled.div`
  width: 518px;
  height: 297px;
  border: 1px solid transparent;
  border-image: linear-gradient(0deg, #caa577, #000) 10 10;
  background: linear-gradient(0deg, #2e2f30 0%, rgba(68, 68, 68, 0.2) 100%);
  position: relative;
  > video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 30px;
    bottom: 34px;
    ${Media.phone`
      left: 20px;
      bottom: 20px;
    `}
  }
  ${Media.phone`
    width: 90%;
    height: 180px;
  `}
`

export const RightContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > p:nth-child(1) {
    color: #caa577;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    ${Media.phone`
      font-size: 24px;
    `}
  }
  > p:not(:nth-child(1)) {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    white-space: nowrap;
    ${Media.phone`
      font-size: 12px;
    `}
  }
  ${Media.phone`
    transform: scale(0.8) translateX(-20px);
    width: 90%;
  `}
`

export const Download3DTemplateBtn = styled.a`
  display: block;
  width: 201px;
  height: 41px;
  border: 1px solid #caa577;
  font-size: 18px;
  line-height: 41px;
  font-weight: bold;
  text-align: center;
  margin-top: ${({ top }) => (top ? top + 'px' : 'unset')};
  svg {
    position: relative;
    left: -8px;
    top: 4px;
  }
`

export const BottomInfoWrapper = styled(ContestAwardWrapper)`
  width: 1125px;
  padding-top: 21px;
  border-top: none;
  position: relative;
  ${Media.phone`
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
  `}
`

export const BottomInfoMenuWrapper = styled(AwardLeftMenuWrapper)`
  justify-content: flex-start;
  ${Media.phone`
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-wrap: nowrap;
    width: 100px;
    margin: 0;
    transform: unset;
  `}
`

export const BottomInfoItem = styled(AwardLeftItem)`
  width: 181px;
  ${Media.phone`
    width: 100px;
    height: 40px;
    ${({ isActive }) =>
      isActive &&
      css`
        border-image: linear-gradient(to right, #caa577, transparent) 10 10;
        border-right: none;
        background: linear-gradient(to right, #2e2f30 0%, transparent 100%);
      `}
  `}
`

export const BottomInfoContentWrapper = styled.div`
  height: ${({ height }) => (height ? height : '70%')};
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #444;
  }
  width: calc(100% - 210px);
  display: flex;
  flex-wrap: wrap;
  align-items: ${({ single }) => (single ? 'flex-start' : 'center')};
  column-gap: 30px;
  padding: 40px 0 40px 40px;
  row-gap: 40px;
  ${Media.phone`
    width: 300px;
    padding: 0;
    transform: scale(0.9);
    row-gap: 20px;
    height: 300px;
    overflow-y: scroll;
  `}
`

export const MiddleButtonWrapper = styled.div`
  margin-bottom: 140px;
  display: flex;
  justify-content: center;
  column-gap: 120px;
  width: 100%;
`

export const MiddleButtonLink = styled.a`
  border-radius: 10px;
  width: 241px;
  height: 51px;
  background: #caa577;
  text-align: center;
  line-height: 51px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  display: block;
`

export const BottomInfoContentItem = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height + 'px' : '80px')};
  position: relative;
  .bottom-mobile {
    display: none;
  }
  > p:nth-child(1) {
    color: #caa577;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 14px;
    ${Media.phone`
      font-size: 16px;
      margin-bottom: 6px;
    `}
  }
  > p:not(:nth-child(1)) {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 13px;
    line-height: 2.5em;
    margin: 0;
    ${Media.phone`
      font-size: 12px;
      line-height: 21px;
    `}
  }
  ${Media.phone`
    width: 200px;
    height: ${({ mobileHeight }) => (mobileHeight ? mobileHeight + 'px' : '80px')};
    .bottom-mobile {
      display: block;
    }
    .bottom-pc {
      display: none;
    }
  `}
  p.note-text {
    color: #caa577;
    font-size: 14px;
    font-weight: 400;
  }
  > section {
    display: flex;
    position: absolute;
    bottom: 0;
    ${Media.phone`
      flex-wrap: wrap;
      row-gap: 10px;
    `}
  }
  .bottom-code {
    display: flex;
    align-items: center;
    justify-content: space-around;
    p {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      ${Media.phone`
      font-size: 12px;
      line-height: 21px;
      margin-bottom: 14px;
    `}
    }
    > div:nth-child(1) {
      height: 160px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    > div:nth-child(2) {
      p {
        margin-bottom: 6px;
      }
    }
    ${Media.phone`
      flex-wrap: wrap;
    `}
  }
`

export const DownloadLink = styled.a`
  width: ${({ width }) => (width ? width + 'px' : '161px')};
  height: 41px;
  line-height: 41px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid #caa577;
  display: block;
  margin-right: 30px;
`

export const DesignIntroTitle = styled.div`
  width: 261px;
  height: 61px;
  background: linear-gradient(to bottom, rgba(195, 149, 118, 0.8) 0%, rgba(68, 45, 30, 0.1) 100%);
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(0deg, #000, #caa577) 10 10;
  border-bottom: 0;
  position: absolute;
  top: -30px;
  left: calc((100% - 261px) / 2);
  backdrop-filter: blur(3px);
  > p {
    font-size: 30px;
    text-align: center;
    font-weight: bolder;
    line-height: 61px;
    background: linear-gradient(to bottom, #fff, #caa577);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const QuestionWrapper = styled.div`
  width: 100%;
  > div {
    margin: 0 auto;
    margin-bottom: 5px;
  }
`

export const QuestionItem = styled.div`
  width: 90%;
  padding: 20px 23px;
  p {
    color: #fff;
    font-size: 14px;
  }
  :last-child {
    margin-bottom: 60px;
  }
  ${({ index }) =>
    index % 2 !== 0
      ? css`
          border: 1px solid transparent;
          border-image: linear-gradient(to right, transparent, #caa577) 10 10;
          background: linear-gradient(to left, #444444, transparent);
          border-left: none;
        `
      : css`
          border: 1px solid transparent;
          border-image: linear-gradient(to left, transparent, #caa577) 10 10;
          background: linear-gradient(to right, #444444, transparent);
          border-right: none;
        `}
  ${Media.phone`
    width: 100%;
  `}
`

export const BottomFormWrapper = styled.div`
  width: 1200px;
  height: 100%;
  ${Media.phone`
    width: 100%;
  `}
`
