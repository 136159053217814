import React from 'react'
import { Title, TitleWrapper, MainVideoWrapper } from './win-highlight-collection.atom'
import { Video } from './components/video'
import {
  collectionMP4,
  omniverse,
  students,
  professions,
  wanhuaSpecial,
  mostPopular,
} from './utils'
import Exibition from './exibition'

function WinHighlightCollection() {
  return (
    <>
      <TitleWrapper id='result'>
        <Title>入围作品集锦</Title>
      </TitleWrapper>
      <MainVideoWrapper>
        <Video src={collectionMP4} />
      </MainVideoWrapper>
      <Exibition
        groups={[
          {
            name: 'Omniverse组',
            group: omniverse,
          },
          {
            name: '专业组',
            group: professions,
          },
          {
            name: '学生组',
            group: students,
          },
          {
            name: '万生华态特别奖',
            group: wanhuaSpecial,
          },
          {
            name: '最佳人气奖',
            group: mostPopular,
          },
        ]}
      />
    </>
  )
}

export default WinHighlightCollection
