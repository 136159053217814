import styled, { css } from 'styled-components'
import { Media } from '../../style/theme'

export const FormWrapper = styled.div`
  padding: 80px 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #444444, #222222);
  border: 1px solid transparent;
  border-image: linear-gradient(to top, #000, #caa577) 10 10;
  border-bottom: none;
  select {
    background: #333333;
    border: 1px solid #444444;
    border-radius: 4px;
    height: 31px;
    color: #fff;
    width: 100%;
    cursor: pointer;
  }
  section {
    padding: 0 10px;
    font-size: 14px;
  }
  .mobile {
    display: none;
  }
  ${Media.phone`
    .pc {
      display: none;
    }
    .mobile {
      display: block;
    }
  `}
`

export const Title = styled.p`
  font-size: 30px;
  margin-bottom: 30px;
  background: linear-gradient(to bottom, #fff, #caa577);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`

export const FormContentWrapper = styled.form`
  width: 55%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  ${Media.phone`
    width: 95%;
  `}
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  column-gap: 30px;
  color: #999999;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  row-gap: ${({ rowGap }) => (rowGap ? rowGap : 'unset')};
  ${Media.phone`
    column-gap: 0;
  `}
`

export const FormItem = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  display: flex;
  align-items: center;
  p {
    color: #999999;
    font-size: 14px;
    padding: 0 10px;
  }
`

export const StyledInput = styled.input`
  background: #333333;
  border: 1px solid #444444;
  border-radius: 4px;
  height: 31px;
  width: ${({ width }) => (width ? width + 'px' : '100%')};
  padding: 3px 20px;
  color: #fff;
  ${({ lineInput }) =>
    lineInput &&
    css`
      background: unset;
      border-color: transparent;
      border-bottom: 1px solid #999999;
      ${Media.phone`
        width: 50px;
      `}
    `}
`

export const SubmitButton = styled.button`
  width: 160px;
  height: 40px;
  background: ${({ disabled }) => (disabled ? '#999999' : '#c1a283')};
  border-radius: 6px;
  border: 0;
  color: #fff;
  margin: 0 auto;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`

export const Link = styled.a`
  color: #999999;
  display: inline;
  text-decoration: underline;
`

export const Span = styled.span`
  color: #ffffff;
`

export const CodeWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  margin-right: 30px;
  ${Media.phone`
    margin-right: 0;
  `}
`
