import React, { useCallback } from 'react'
import { CheckboxWrap, LabelText, StyledCheckbox, StyledInput } from './checkbox.style'

const Checkbox = ({ onChange, checked, children, value }) => {
  const handleChange = useCallback(
    (checked) => {
      onChange?.(checked)
    },
    [onChange],
  )
  return (
    <CheckboxWrap>
      <StyledInput
        type='checkbox'
        name='checkbox'
        value={value}
        checked={checked}
        onChange={(e) => handleChange(e.target.checked)}
      ></StyledInput>
      <StyledCheckbox checked={checked}></StyledCheckbox>
      {children && <LabelText>{children}</LabelText>}
    </CheckboxWrap>
  )
}

export default Checkbox
