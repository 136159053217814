import styled from 'styled-components'
import { Media } from '../../style/theme'

export const DateboardWrapper = styled.div`
  width: 180px;
  height: 200px;
  position: fixed;
  left: 39px;
  bottom: 200px;
  z-index: 998;
  section {
    position: absolute;
    text-align: center;
    color: #fff;
    left: 57px;
    top: 36px;
    z-index: 999;
    > p:nth-child(1) {
      height: 60px;
      line-height: 60px;
      font-size: 60px;
      font-weight: bold;
      margin-bottom: 9px;
    }
    > p:nth-child(2) {
      font-size: 16px;
      font-weight: 400;
    }
  }
  ${Media.phone`
    display: none;
  `}
`

export const StyledButton = styled.a`
  width: 120px;
  height: 40px;
  background: #caa577;
  border-radius: 10px;
  outline: 0;
  border: 0;
  color: #fff;
  font-size: 16px;
  position: absolute;
  left: 30px;
  bottom: 20px;
  z-index: 999;
  cursor: pointer;
  display: block;
  text-align: center;
  line-height: 40px;
`
