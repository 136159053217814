export const jobs = [
  '首席执行官（CEO）',
  '首席信息官（CIO）',
  '首席技术官（CTO）',
  '顾问',
  '数据科学家',
  '开发者/编程人员',
  '工程师',
  '电影/视频编辑',
  '平面设计师/动画师',
  '工业设计师/产品设计师',
  'IT 管理员/系统管理员',
  'IT 经理/IT 总监/IT 负责人',
  '市场经理',
  '教授/学院指导员',
  '采购经理',
  '科研人员',
  '销售工程师',
  '销售代表/销售经理',
  '学生/研究生',
  '其他',
]

export const works = [
  '航空航天',
  '建筑/工程/建设',
  '汽车',
  '国防/政府部分',
  '金融服务业（FSI）',
  '医疗保健',
  '高等教育/科研',
  'IT 服务业',
  '制造业',
  '媒体/娱乐',
  '石油和天然气/能源',
  '医疗影像/生命科学仪器',
  '实体零售/电商',
  '超算中心',
  '电信',
  '其他',
]

export const whereToKnowThisContest = [
  ['英伟达相关平台', '3DCAT相关平台'],
  ['中视典相关平台', 'renderbus相关平台'],
  ['D5相关平台', '英迈相关平台'],
  ['青椒云相关平台', '七点GAME相关平台'],
]

export const hasPlanToBuyGPU = [
  '已部署',
  '考虑6个月内采购或升级',
  '可能在未来1年内考虑采购或升级',
  '无采购和升级计划',
]

export const collectionMP4 =
  'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E5%85%A5%E5%9B%B4%E4%BD%9C%E5%93%81%E5%90%88%E9%9B%86/%E5%85%A5%E5%9B%B4%E4%BD%9C%E5%93%81%E5%90%88%E9%9B%86-03.19-%E6%9C%89%E9%9F%B3%E6%95%88.mp4'

export const tabMap = new Map([
  ['champion', '冠军'],
  ['secondPlace', '亚军'],
  ['thirdPlace', '季军'],
])

export const omniverse = {
  champion: [
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/OV%E7%BB%84/%E3%80%8A%E4%B8%87%E9%87%91%E6%B2%B9%E3%80%8B_%E8%80%83%E6%8B%89OK_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91.mp4',
      name: '万金油',
      author: '考拉OK',
      desc: '啥都会一点儿,啥都不精,在说我自己',
      softwares: 'Zbrush/blender/SP/Omniverse',
      hardwareConfig: 'win10,3900X,3090显卡,64G内存',
      passage: {
        text: '专访冠军考拉ok，“新人问我学Blender能找到工作吗，我回复不能”',
        link: 'https://www.3dcat.live/news/ok-blender/',
      },
    },
  ],
  secondPlace: [
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/OV%E7%BB%84/%E5%93%8E_%E8%B5%B5%E7%89%A7%E7%9F%B3_%E5%8A%A8%E7%94%BB%E4%BD%9C%E5%93%81_%E6%9C%80%E7%BB%88%E4%BF%AE%E6%AD%A3%E7%89%882.mp4',
      name: '哎?',
      author: '赵牧石',
      desc:
        '宇航员外出修管道,但是把管道接上舱门却关了。宇航员为omni自带的资产,blender中进行了绑定以及优化。其余资产使用blender自制。',
      softwares: 'blender/omniverse',
      hardwareConfig: 'win10,cpu:3950x,gpu:2080ti,内存:64gb',
      passage: {
        text: '专访OV组亚军｜30岁的我，如何从码农转CG且获奖？',
        link: 'https://www.3dcat.live/news/post-id-66/',
      },
    },
  ],
  thirdPlace: [
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/OV%E7%BB%84/BB%20LOBY_%E6%9D%8E%E6%99%BA%E4%B8%80_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91___________%E6%9C%80%E6%9C%80%E6%9C%80%E6%9C%80%E6%9C%80%E7%BB%88%E7%89%88.mp4',
      name: 'BB LOBY',
      author: '李智一',
      desc: '有一个小可爱不经意间闯入了第一秩序的BB休息厅,他是谁呢?哦!他是反叛军的成员!李智一',
      softwares: 'C4D,Houdini,SP,P,omniverse',
      hardwareConfig: 'win10,i9,RTX3060,32G',
      passage: {
        text: '专访OV季军｜毕业转为freelancer，他如何斩获大量CG奖项？',
        link: 'https://www.3dcat.live/news/post-id-74/',
      },
    },
  ],
}

export const professions = {
  champion: [
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E4%B8%93%E4%B8%9A%E7%BB%84/dune-dkyil-vkhor_%E7%BD%97%E8%B6%85_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91.mp4',
      name: 'cyber-dkyil-vkhor-2',
      author: '罗超',
      desc:
        '此作品为《cyber-dkyil-vkhor》的第二部分，讲述的是主人公“大明”通过元宇宙通道传输技术，来到了一个以《2001宇宙奥德赛》为背景的的元宇宙。亲眼看到人类智慧打开的哪一个瞬间。猿人触碰到代表未来的黑色巨石的瞬间，就代表了未来以来。就像是我们第一次接触实时渲染。',
      softwares: 'UE4',
      hardwareConfig: 'rtx2060',
    },
  ],
  secondPlace: [
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E4%B8%93%E4%B8%9A%E7%BB%84/%E4%B8%89%E5%90%88(%E9%85%8D%E9%9F%B3%E7%89%88).mp4',
      name: '三合',
      author: '刘洋',
      desc: '作品是上中下三合其中的中合，是飘在空中的城市，从地面吸取能源，用能源锁释放能源。',
      softwares: 'UE5',
      hardwareConfig: '5900X  3090',
    },
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E4%B8%93%E4%B8%9A%E7%BB%84/cyber-dkyil-vkhor_%E4%BD%95%E8%8D%99%E9%93%AD_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91.mp4',
      name: 'cyber-dkyil-vkhor',
      author: '何荙铭',
      desc:
        'dkyil-vkhor：藏语“坛城”。世界设定于未来3193年的中国。人类完成了机器改造。他们进入元宇宙的方式是通过“禅坐”实现的。此作品分为阴阳两部。分别未进入元宇宙前。和进入元宇宙后的世界。此部为“阳”，讲述的是大明正在进入元宇宙。',
      softwares: 'UE4',
      hardwareConfig: 'RTX1080*2SLI：AMD1920：WIN10：DDR4 40GB',
    },
  ],
  thirdPlace: [
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E4%B8%93%E4%B8%9A%E7%BB%84/%E6%AE%96%E6%B0%91%E5%9C%B0_FJX_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91.mp4',
      name: '殖民地',
      author: 'FJX',
      desc: '火星殖民地小场景',
      softwares: 'Blender/Eevee',
      hardwareConfig: '系统：win10、CPU:5800X、显卡：GTX960、内存：32G',
    },
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E4%B8%93%E4%B8%9A%E7%BB%84/%E9%80%A0%E7%A5%9E%E5%B7%A5%E5%8E%82_%E6%B8%A9%E9%94%A6%E5%8D%8E_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%917s.mp4',
      name: '女神工厂',
      author: '温锦华',
      desc:
        '在一个未来工厂中，正在生产女神机器人。 画面前景是正在生产的女神眼球，背景是正在组装的头部。',
      softwares: 'maya、3dmax、Unity',
      hardwareConfig: 'nvidia2070',
    },
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E4%B8%93%E4%B8%9A%E7%BB%84/%E9%98%BF%E5%87%A1%E8%BE%BE%E4%BE%8F%E7%BD%97%E7%BA%AA%E5%85%AC%E5%9B%AD_%E9%BB%84%E5%AD%A6%E7%AB%A0.mp4',
      name: '阿凡达侏罗纪',
      author: '黄学章',
      desc:
        '话说在阿凡达星球也有侏罗纪公园，一个机械托盘送入一只阿凡达羊，两只恐龙争吃。点击鼠标可互动。',
      softwares: 'maya，unity',
      hardwareConfig: 'nvidia2070',
    },
  ],
}

export const students = {
  champion: [
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E5%AD%A6%E7%94%9F%E7%BB%84/%E9%A3%8E%E6%B4%9E%E6%B5%8B%E8%AF%95_%E7%8E%8B%E5%95%86%E7%BE%BD_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91.mp4',
      name: '风洞测试',
      author: '王商羽',
      desc: '未来宇宙飞船的风洞测试坠毁的一个画面',
      softwares: 'Maya/Houdini/Substancepainter/Omniverse',
      hardwareConfig: 'Window10 Amd5800hx Rtx3080Laptop 64GB',
    },
  ],
  secondPlace: [
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E5%AD%A6%E7%94%9F%E7%BB%84/15%E8%99%8E%E8%99%8E%E7%94%9F%E5%A8%81_%E5%90%91%E6%98%B1.mp4',
      name: '虎虎生威',
      author: '向昱',
      desc:
        '家中的老茶几里暗藏玄机，原来是藏了只小老虎，在深夜它会悄悄现身，别看小老虎憨态可掬，性子可很急，把它惹生气了可是会发火，向你展现老虎的威严。',
      softwares: 'Maya、Substance Painter、Substance Designer、Unreal Engine 5',
      hardwareConfig: 'Windows11、i7-11800H、 RTX3060 Laptop、32G',
    },
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E5%AD%A6%E7%94%9F%E7%BB%84/%E3%80%8Aocean%20crash%E3%80%8B_%E5%BB%96%E6%8C%AF%E5%AE%87_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91_%E6%9C%80%E7%BB%88%E7%89%88.mp4',
      name: 'Ocean crash',
      author: '廖振宇',
      desc: '一艘潜艇坠毁在洋底  一种不属于这个世界的生物被唤醒',
      softwares: 'blender substancepainter evee',
      hardwareConfig: 'win11 i7 1660ti 16g',
    },
  ],
  thirdPlace: [
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E5%AD%A6%E7%94%9F%E7%BB%84/%E8%A3%82%E8%A7%A3%E5%8F%B7%E8%B5%B7%E9%A3%9E__%E9%99%88%E6%98%B1%E5%B7%9E__%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91.mp4',
      name: '裂解号起飞',
      author: '陈昱州',
      desc: '裂解号拦截机正在月球基地起飞执行日常巡逻',
      softwares: 'blender，eevee',
      hardwareConfig: 'WIN10  cpu: i7-11800H  显卡：RTX3050 内存：8X2G 3200hz',
    },
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E5%AD%A6%E7%94%9F%E7%BB%84/%E8%A2%AB%E5%9B%9A%E7%A6%81%E7%9A%84%E7%8E%8B%E5%BA%A7__%E6%9D%8E%E6%B5%B7%E6%B4%8B__%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91%EF%BC%88%E4%BB%A5%E6%AD%A4%E7%89%88%E6%9C%AC%E4%B8%BA%E5%87%86%EF%BC%89.mp4',
      name: '被囚禁的王座',
      author: '李海洋',
      desc:
        '未来外星城市一片残骸，其中就有一个被囚禁的王座。他出现后不甘的怒吼着想要逃离这个星球，地面因此产生裂痕，但此时监视他的飞船飞来，王座不得已沉入地底',
      softwares: 'C4D/UE',
      hardwareConfig:
        'WIN10；11th Gen Intel(R) Core(TM) i7-11800H @ 2.30GHz   2.30 GHz；Intel(R)UHD Graphics;32GB',
    },
    {
      link:
        'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E5%AD%A6%E7%94%9F%E7%BB%84/%E8%87%AA%E5%8A%A8%E5%8C%96%E5%9C%B0%E7%8B%B1%20_%E5%91%A8%E6%98%89_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91.mp4',
      name: '自动化地狱',
      author: '周昉',
      desc:
        '进入20世纪以来，人类人口呈爆发式增长，地狱传统的惩罚模式和工具无法应对大量死亡的人类，然而当推动科技革命的先驱们离世后，他们将科技引入了地狱，甚至许多小鬼直接由机器人替代。该场景描绘了审判中的某一环：管道中的人类被热压成规整的立方体，送往流水线，等待他们的是下一轮的审判......',
      softwares: '3dsMax、Zbrush、MarvelousDesigner、Rizomuv、Cinema4D、UE5.0.0',
      hardwareConfig: 'Win10、intel i7-8750H、GTX 1060、32G',
    },
  ],
}

export const wanhuaSpecial = [
  {
    link:
      'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/%E5%AD%A6%E7%94%9F%E7%BB%84/15%E8%99%8E%E8%99%8E%E7%94%9F%E5%A8%81_%E5%90%91%E6%98%B1.mp4',
    name: '虎虎生威',
    author: '向昱',
    desc:
      '家中的老茶几里暗藏玄机，原来是藏了只小老虎，在深夜它会悄悄现身，别看小老虎憨态可掬，性子可很急，把它惹生气了可是会发火，向你展现老虎的威严。',
    softwares: 'Maya、Substance Painter、Substance Designer、Unreal Engine 5',
    hardwareConfig: 'Windows11、i7-11800H、 RTX3060 Laptop、32G',
  },
]

export const mostPopular = [
  {
    link:
      'https://3dcat-cms-img.oss-cn-shenzhen.aliyuncs.com/contest-2021/OV%E7%BB%84/BB%20LOBY_%E6%9D%8E%E6%99%BA%E4%B8%80_%E5%8A%A8%E7%94%BB%E8%A7%86%E9%A2%91___________%E6%9C%80%E6%9C%80%E6%9C%80%E6%9C%80%E6%9C%80%E7%BB%88%E7%89%88.mp4',
    name: 'BB LOBY',
    author: '李智一',
    desc: '有一个小可爱不经意间闯入了第一秩序的BB休息厅，他是谁呢？哦！他是反叛军的成员！李智一',
    softwares: 'C4D,Houdini,SP,PR，omniverse',
    hardwareConfig: 'win10,i9,RTX3060,32G',
  },
]
