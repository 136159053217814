import React from 'react'

function DownloadIcon({ style }) {
  return (
    <svg
      style={style}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='17px'
      height='19px'
    >
      <path
        fillRule='evenodd'
        fill='rgb(255, 255, 255)'
        d='M16.34,12.366 L9.729,18.560 C9.442,18.841 9.53,19.0 8.649,19.0 C8.244,19.0 7.855,18.841 7.569,18.560 L1.263,12.366 C0.667,11.780 0.667,10.830 1.263,10.245 C1.860,9.659 2.826,9.659 3.423,10.245 L7.121,13.878 L7.121,1.499 C7.121,0.672 7.806,0.0 8.649,0.0 C9.492,0.0 10.176,0.672 10.176,1.499 L10.176,13.878 L13.874,10.245 C14.471,9.659 15.437,9.659 16.34,10.245 C16.631,10.830 16.631,11.780 16.34,12.366 Z'
      />
    </svg>
  )
}

export default DownloadIcon
