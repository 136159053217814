import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import GlobalStyle from '../style/theme/global'
import Seo from '../components/seo'
import HeaderLogo from '../images/contest/logo.png'
import Arrow from '../images/contest/arrow.png'
import rrcMp4 from '../assets/video/rrc-template.mp4'
import poster from '../images/contest/poster.png'
import zhan from '../images/contest/zhan.png'
import xieban from '../images/contest/xieban.png'
import zhuban from '../images/contest/rayvision.png'
import {
  PageContainer,
  HeaderContainr,
  HeaderWrapper,
  HeaderLeftWrapper,
  HeaderRightWrapper,
  HeaderRightItem,
  HeaderButton,
  BannerWrapper,
  BannerContentWrapper,
  OrganizerWrapper,
  IntroductionWrapper,
  IntroductionTitle,
  LinearGradientTitle,
  IntroductionContent,
  ContestTimeWrapper,
  ContestTimeContent,
  ContestTimeArrorWrapper,
  ContestTimeUpperText,
  GroupContestWrapper,
  GroupContestContentWrapper,
  GroupContestItem,
  GroupContestItemContent,
  ContestAwardWrapper,
  AwardLeftMenuWrapper,
  AwardLeftItem,
  AwardRightContentWrapper,
  AwardItem,
  AwardItemTitle,
  AardItemTag,
  AwardDetail,
  MainJudgeWrapper,
  PeoplePictureWrapper,
  VideoWrapper,
  MiddleButtonWrapper,
  MiddleButtonLink,
  LeftVideoContainer,
  RightContentWrapper,
  Download3DTemplateBtn,
  BottomInfoWrapper,
  BottomInfoMenuWrapper,
  BottomInfoItem,
  BottomInfoContentWrapper,
  BottomInfoContentItem,
  DownloadLink,
  DesignIntroTitle,
  QuestionItem,
  QuestionWrapper,
  BottomFormWrapper,
} from '../style/pages/render-contest.atom'
import DownloadIcon from '../components/icons/rrc/download-icon'
import { FormSubmit, DateBoard, WinHighlightCollection } from '../components/rrc'

const contestAward = [
  {
    title: 'Omniverse组',
    value: 'omniverse',
  },
  {
    title: '专业组',
    value: 'profession',
  },
  {
    title: '学生组',
    value: 'student',
  },
  {
    title: '万生华态特别奖',
    value: 'wansheng',
  },
  {
    title: '最佳人气奖',
    value: 'popular',
  },
  {
    title: '更多荣誉',
    value: 'more',
  },
]

const bottomInfoItems = [
  {
    title: '评选标准',
    value: 'base',
  },
  {
    title: '技术支持',
    value: 'support',
  },
  {
    title: '赛事交流',
    value: 'communicate',
  },
  {
    title: '获奖通知',
    value: 'notify',
  },
  {
    title: '相关权益',
    value: 'right',
  },
]

const designIntroduction = [
  {
    title: '参赛须知',
    value: 'know',
  },
  {
    title: '作品提交内容',
    value: 'commit',
  },
  {
    title: '3D场景规格',
    value: 'scene',
  },
]

const RenderContest = () => {
  const [awardGroup, setAwardGroup] = React.useState('omniverse')
  const [bottomInfoItem, setBottomInfoItem] = React.useState('base')
  const [designIntro, setDesignIntro] = React.useState('know')

  return (
    <>
      <PageContainer>
        <Seo
          title='「新锐先锋,玩转未来」 首届实时渲染3D动画创作大赛'
          description='你是否时刻迸发着奇妙的灵感，你是否怀抱着天马行空的创造力?瑞云、NVIDIA等官方将为你提供全球展示机会，在NVIDIA总部创意画廊一展风采,寻找新锐先锋，一起玩转未来！'
          keywords='3D动画创作大赛,实时渲染动画大赛,动画创作大赛'
        />
        <DateBoard />
        <HeaderContainr>
          <HeaderWrapper>
            <HeaderLeftWrapper>
              <img src={HeaderLogo} alt='' />
            </HeaderLeftWrapper>
            <HeaderRightWrapper>
              <HeaderRightItem href='https://www.rayvision.com/rrc.html'>首页</HeaderRightItem>
              <HeaderRightItem href='#result'>赛果公布</HeaderRightItem>
              <HeaderRightItem href='#event-details'>赛事详情</HeaderRightItem>
              <HeaderRightItem href='#form-submit'>我要参赛</HeaderRightItem>
              <HeaderRightItem href='#event-information'>赛事资料</HeaderRightItem>
              <HeaderRightItem href='#qa'>常见问题</HeaderRightItem>
              {/* <HeaderRightItem>获奖名单</HeaderRightItem> */}
              <HeaderButton as='a' href='#form-submit'>
                报名参加
              </HeaderButton>
            </HeaderRightWrapper>
          </HeaderWrapper>
        </HeaderContainr>
        <BannerWrapper>
          <div className='pc-banner-container'>
            <StaticImage
              src='../images/contest/banner_bg@2x.png'
              alt='banner.png'
              layout='fullWidth'
            />
          </div>
          <div className='mobile-banner-container'>
            <StaticImage src='../images/contest/banner_bg_mobile.png' alt='mobile.png' />
          </div>
          <BannerContentWrapper>
            <div className='pc-banner'>
              <StaticImage src='../images/contest/title.png' alt='title.png' />
            </div>
            <div className='mobile-banner'>
              <StaticImage src='../images/contest/title-mobile.png' alt='title-mobile.png' />
            </div>
            <div className='second-title'>
              <StaticImage src='../images/contest/second-title.png' alt='second-title.png' />
            </div>
            <div className='contest-time'>
              <p>赛事时间:2021.12.10 - 2022.3.10（UTC+8）</p>
            </div>
            <OrganizerWrapper>
              <div>
                <p>主办方</p>
                <img src={zhuban} alt='rayvision.png' />
              </div>
              <div>
                <p>协办方</p>
                <img src={xieban} alt='rayvision.png' />
              </div>
              <div>
                <p>赞助方</p>
                <img src={zhan} alt='rayvision.png' />
              </div>
            </OrganizerWrapper>
            <WinHighlightCollection />
            <IntroductionWrapper>
              <IntroductionTitle>
                <LinearGradientTitle>引言</LinearGradientTitle>
              </IntroductionTitle>
              <IntroductionContent>
                <p>
                  你是否时刻迸发着
                  <strong>
                    <i>奇妙的灵感</i>
                  </strong>
                  ，
                </p>
                <p>
                  你是否怀抱着天马行空的
                  <strong>
                    <i>创造力</i>
                  </strong>
                  ，
                </p>
                <p style={{ marginBottom: 50 }}>
                  你是否
                  <strong>
                    <i>热爱探索</i>
                  </strong>
                  前沿的CG技术，
                </p>
                <p>
                  那么，我们
                  <strong>
                    <i>找的就是你</i>
                  </strong>
                  !
                </p>
                <p style={{ marginBottom: 50 }}>寻找新锐先锋，一起玩转未来！</p>
                <p>这个平台，等你来挥洒创意。</p>
                <p>这个平台，等你来肆意造梦。</p>
                <p style={{ marginBottom: 50 }}>这个平台，等你来绽放异彩。</p>
                <p>只要你的作品足够优秀，</p>
                <p>瑞云、NVIDIA等官方将为你提供全球展示机会，</p>
                <p>与全球顶尖创作者的作品一起，</p>
                <p>在NVIDIA总部创意画廊一展风采。</p>
              </IntroductionContent>
            </IntroductionWrapper>
            <ContestTimeWrapper>
              <LinearGradientTitle id='event-details'>大赛时间</LinearGradientTitle>
              <ContestTimeContent>
                <div>2021.12.10</div>
                <ContestTimeArrorWrapper>
                  <p>作品征集期</p>
                  <div className='arrow'>
                    <img src={Arrow} alt='arrow.png' />
                  </div>
                </ContestTimeArrorWrapper>
                <div>2022.03.10</div>
                <ContestTimeArrorWrapper>
                  <p>评选时间</p>
                  <div className='arrow'>
                    <img src={Arrow} alt='arrow.png' />
                  </div>
                </ContestTimeArrorWrapper>
                <div>2022.03.25</div>
                <ContestTimeArrorWrapper>
                  <div className='arrow' style={{ height: 40, paddingTop: 10 }}>
                    <img src={Arrow} alt='arrow.png' />
                  </div>
                </ContestTimeArrorWrapper>
                <div>
                  <ContestTimeUpperText>赛果公布{'&'}颁奖典礼</ContestTimeUpperText>
                  <div>2022.03.31</div>
                </div>
              </ContestTimeContent>
            </ContestTimeWrapper>

            <GroupContestWrapper>
              <LinearGradientTitle>大赛组别</LinearGradientTitle>
              <GroupContestContentWrapper>
                <GroupContestItem>
                  <section>
                    <StaticImage src='../images/contest/omniverse.png' alt='omniverse' />
                  </section>
                  <GroupContestItemContent>
                    <p>Omniverse组</p>
                    <div style={{ marginTop: 10 }} className='group-pc'>
                      <p>针对使用NVIDIA Omniverse进行创</p>
                      <p style={{ marginTop: 12 }}>作的CG艺术家/团队</p>
                    </div>
                    <div className='group-mobile' style={{ marginTop: 8 }}>
                      <p>针对使用NVIDIA</p>
                      <p style={{ marginTop: 8 }}>Omniverse进行创作</p>
                      <p style={{ marginTop: 8 }}>的CG艺术家/团队</p>
                    </div>
                  </GroupContestItemContent>
                </GroupContestItem>
                <GroupContestItem>
                  <section>
                    <StaticImage src='../images/contest/profession.png' alt='profession' />
                  </section>
                  <GroupContestItemContent>
                    <p>专业组</p>
                    <div style={{ marginTop: 10 }} className='group-pc'>
                      <p>面向所有CG艺术家、CG从业者、</p>
                      <p style={{ marginTop: 12 }}>CG爱好者</p>
                    </div>
                    <div className='group-mobile' style={{ marginTop: 8 }}>
                      <p>面向所有CG艺术家</p>
                      <p style={{ marginTop: 8 }}>、CG从业者、CG爱</p>
                      <p style={{ marginTop: 8 }}>好者</p>
                    </div>
                  </GroupContestItemContent>
                </GroupContestItem>
                <GroupContestItem>
                  <section>
                    <StaticImage src='../images/contest/student.png' alt='student' />
                  </section>
                  <GroupContestItemContent>
                    <p>学生组</p>
                    <div style={{ marginTop: 10 }} className='group-pc'>
                      <p>针对在校学生，报名该组别需提交</p>
                      <p style={{ marginTop: 12 }}>学生证</p>
                    </div>
                    <div className='group-mobile' style={{ marginTop: 8 }}>
                      <p>针对在校学生，报</p>
                      <p style={{ marginTop: 8 }}>该组别需提交学</p>
                      <p style={{ marginTop: 8 }}>生证</p>
                    </div>
                  </GroupContestItemContent>
                </GroupContestItem>
              </GroupContestContentWrapper>
            </GroupContestWrapper>
            <LinearGradientTitle style={{ marginBottom: 60 }}>大赛奖品</LinearGradientTitle>
            <ContestAwardWrapper>
              <AwardLeftMenuWrapper>
                {contestAward.map((item) => (
                  <AwardLeftItem
                    key={item.value}
                    isActive={awardGroup === item.value}
                    onMouseEnter={() => setAwardGroup(item.value)}
                    onClick={() => setAwardGroup(item.value)}
                  >
                    {item.title}
                  </AwardLeftItem>
                ))}
              </AwardLeftMenuWrapper>

              {awardGroup === 'profession' && (
                <AwardRightContentWrapper>
                  <AwardItem pcWidth={300}>
                    <AwardItemTitle>
                      <p>冠军1人</p>
                      <AardItemTag>
                        奖品总价<strong>5W+</strong>
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>英伟达专业显卡A5000（价值25999元）</p>
                      <p>
                        Reallusion 3D角色设计&动画制程组合 v2
                        <span className='is-mobile'>（价值11300元）</span>
                      </p>
                      <p className='is-pc'>（价值11300元）</p>
                      <p>
                        中视典VRP 3D个人终身使用权 （一节点，价
                        <span className='is-mobile'>值9799元）</span>
                      </p>
                      <p className='is-pc'>值9799元）</p>
                      <p>PBRMAX专业版1年（价值2680元）</p>
                      <p>3DCAT实时渲染云2000元代金券</p>
                      <p>D5渲染器专业版1年（价值1920元）</p>
                    </AwardDetail>
                  </AwardItem>

                  <AwardItem pcWidth={300}>
                    <AwardItemTitle>
                      <p>亚军2人</p>
                      <AardItemTag>
                        奖品总价<strong>1W+</strong>
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>Wacom数位板DTK1661套装（价值5000元）</p>
                      <p>Reallusion 3D角色动画专业工具包（价值5700元）</p>
                      <p>
                        中视典VRP 3D两年使用权 （一节点，价
                        <span className='is-mobile'>值4999元）</span>
                      </p>
                      <p className='is-pc'>值4999元）</p>
                      <p>3DCAT实时渲染云1500元代金券</p>
                      <p>PBRMAX专业版6个月（价值1340元）</p>
                      <p>D5渲染器专业版1个月+定制周边礼盒（价值600元）</p>
                    </AwardDetail>
                  </AwardItem>

                  <AwardItem pcWidth={300}>
                    <AwardItemTitle>
                      <p>季军3人</p>
                      <AardItemTag>
                        奖品总价<strong>1W+</strong>
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>BOSE Earbuds无线消噪耳机（价值3000元）</p>
                      <p>Reallusion 3D角色动画专业工具包（价值5700元）</p>
                      <p>
                        中视典VRP 3D一年使用权 （一节点，价
                        <span className='is-mobile'>值3999元）</span>
                      </p>
                      <p className='is-pc'>值3999元）</p>
                      <p>PBRMAX专业版3个月（价值670元）</p>
                      <p>3DCAT实时渲染云500元代金券</p>
                    </AwardDetail>
                  </AwardItem>
                </AwardRightContentWrapper>
              )}

              {awardGroup === 'student' && (
                <AwardRightContentWrapper>
                  <AwardItem pcWidth={300}>
                    <AwardItemTitle>
                      <p>冠军1人</p>
                      <AardItemTag>
                        奖品总价<strong>3W+</strong>
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>英伟达专业显卡A4000（价值9798元）</p>
                      <p>
                        Reallusion 3D 角色设计&动画制程组合 v2
                        <span className='is-mobile'>（价值11300元）</span>
                      </p>
                      <p className='is-pc'>（价值11300元）</p>
                      <p>
                        中视典VRP 3D个人终身使用权 （一节点，价
                        <span className='is-mobile'>值9799元）</span>
                      </p>
                      <p className='is-pc'>值9799元）</p>
                      <p>PBRMAX专业版1年（价值2680元）</p>
                      <p>3DCAT实时渲染云2000元代金券</p>
                      <p>D5渲染器专业版1年（价值1920元）</p>
                    </AwardDetail>
                  </AwardItem>

                  <AwardItem pcWidth={300}>
                    <AwardItemTitle>
                      <p>亚军2人</p>
                      <AardItemTag>
                        奖品总价<strong>1W+</strong>
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>Wacom数位板DTC133（价值3200元）</p>
                      <p>Reallusion 3D角色动画专业工具包（价值5700元）</p>
                      <p>
                        中视典VRP 3D两年使用权 （一节点，价
                        <span className='is-mobile'>值4999元）</span>
                      </p>
                      <p className='is-pc'>值4999元）</p>
                      <p>3DCAT实时渲染云1500元代金券</p>
                      <p>PBRMAX专业版6个月（价值1340元）</p>
                      <p>D5渲染器专业版1个月+定制周边礼盒（价值600元）</p>
                    </AwardDetail>
                  </AwardItem>

                  <AwardItem pcWidth={300}>
                    <AwardItemTitle>
                      <p>季军3人</p>
                      <AardItemTag>
                        奖品总价<strong>1W+</strong>
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>Bose QC35二代蓝牙降噪耳机（价值1300元）</p>
                      <p>Reallusion 3D角色动画专业工具包（价值5700元）</p>
                      <p>
                        中视典VRP 3D一年使用权 （一节点，价
                        <span className='is-mobile'>值3999元）</span>
                      </p>
                      <p className='is-pc'>值3999元）</p>
                      <p>PBRMAX专业版3个月（价值670元）</p>
                      <p>3DCAT实时渲染云500元代金券</p>
                    </AwardDetail>
                  </AwardItem>
                </AwardRightContentWrapper>
              )}

              {awardGroup === 'omniverse' && (
                <AwardRightContentWrapper>
                  <AwardItem pcWidth={300}>
                    <AwardItemTitle>
                      <p>冠军1人</p>
                      <AardItemTag>
                        奖品总价<strong>7W+</strong>
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>英伟达专业显卡A6000（价值48999元）</p>
                      <p>
                        Reallusion 3D角色设计&动画制程组合 v2
                        <span className='is-mobile'>（价值11300元）</span>
                      </p>
                      <p className='is-pc'>（价值11300元）</p>
                      <p>
                        中视典VRP 3D个人终身使用权 （一节点，价
                        <span className='is-mobile'>值9799元）</span>
                      </p>
                      <p className='is-pc'>值9799元）</p>
                      <p>PBRMAX专业版1年（价值2680元）</p>
                      <p>3DCAT实时渲染云2000元代金券</p>
                    </AwardDetail>
                  </AwardItem>

                  <AwardItem pcWidth={300}>
                    <AwardItemTitle>
                      <p>亚军1人</p>
                      <AardItemTag>
                        奖品总价<strong>3W+</strong>
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>戴尔Precision 7560移动工作站（价值26000元）</p>
                      <p>Reallusion 3D角色动画专业工具包（价值5700元）</p>
                      <p>
                        中视典VRP 3D两年使用权 （一节点，价
                        <span className='is-mobile'>值4999元）</span>
                      </p>
                      <p className='is-pc'>值4999元）</p>
                      <p>3DCAT实时渲染云1500元代金券</p>
                      <p>PBRMAX专业版6个月（价值1340元）</p>
                    </AwardDetail>
                  </AwardItem>

                  <AwardItem pcWidth={300}>
                    <AwardItemTitle>
                      <p>季军1人</p>
                      <AardItemTag>
                        奖品总价<strong>2W+</strong>
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>英伟达专业显卡A4000（价值9798元）</p>
                      <p>Reallusion 3D角色动画专业工具包（价值5700元）</p>
                      <p>
                        中视典VRP 3D一年使用权 （一节点，价
                        <span className='is-mobile'>值3999元）</span>
                      </p>
                      <p className='is-pc'>值3999元）</p>
                      <p>PBRMAX专业版3个月（价值670元）</p>
                      <p>3DCAT实时渲染云500元代金券</p>
                    </AwardDetail>
                  </AwardItem>
                </AwardRightContentWrapper>
              )}

              {awardGroup === 'wansheng' && (
                <AwardRightContentWrapper>
                  <AwardItem pcWidth={650} mobileWidth='380px'>
                    <AwardItemTitle>
                      <p>万生华态特别奖</p>
                      <AardItemTag mobileMini>
                        作品使用了万生华态数字资产，即有机会获得万生华态提供的特别奖
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>PBRMAX专业版1年（价值2680元）</p>
                      <p>
                        额外PBRMAX EP点数（支付EP点数可购买数字资产）
                        <span className='is-mobile'>（价值1000元）</span>
                      </p>
                      <p className='is-pc'>（价值1000元）</p>
                      <p>
                        特邀产品体验官，KOC塑造，流媒体平台投流扶持
                        <span className='is-mobile'>（价值1000元）</span>
                      </p>
                      <p className='is-pc'>（价值1000元）</p>
                      <p>EcoPlants周边大礼包</p>
                    </AwardDetail>
                  </AwardItem>
                </AwardRightContentWrapper>
              )}

              {awardGroup === 'popular' && (
                <AwardRightContentWrapper>
                  <AwardItem pcWidth={650} mobileWidth='380px'>
                    <AwardItemTitle>
                      <p>最佳人气奖 1人</p>
                      <AardItemTag mobileMini>
                        最终入围作品将进行网络公开投票，得票最高者获得最佳人气奖
                      </AardItemTag>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>英伟达专业显卡A2000（价值5000元）</p>
                      <p>BOSE Earbuds无线消噪耳机（价值2000元）</p>
                      <p>中视典VRP 3D 6个月使用权 （一节点，价值2199元）</p>
                      <p>PBRMAX专业版6个月（价值1340元）</p>
                      <p>3DCAT实时渲染云500元代金券</p>
                    </AwardDetail>
                  </AwardItem>
                </AwardRightContentWrapper>
              )}

              {awardGroup === 'more' && (
                <AwardRightContentWrapper>
                  <AwardItem pcWidth={650} mobileWidth='380px'>
                    <AwardItemTitle withoutTag>
                      <p>更多荣誉</p>
                    </AwardItemTitle>
                    <AwardDetail>
                      <p>大赛奖杯+荣誉证书</p>
                      <p>NVIDIA中国区全渠道、专业媒体的曝光</p>
                      <p>与全球顶尖创作者的作品一起在NVIDIA总部创意画廊展出</p>
                      <p>受邀采访，采访文章将在各大媒体平台曝光宣传</p>
                      <p>获得直播机会，和广大CG爱好者分享制作经验和心得</p>
                    </AwardDetail>
                  </AwardItem>
                </AwardRightContentWrapper>
              )}
            </ContestAwardWrapper>

            <LinearGradientTitle pcBottom={101} mobileBottom={30}>
              评委阵容
            </LinearGradientTitle>
            <MainJudgeWrapper pcBottom={133} mobileBottom={30}>
              <section>
                <p>大赛主评委</p>
                {/* <p>主评委</p> */}
              </section>
              <PeoplePictureWrapper main>
                <div>
                  <StaticImage src='../images/contest/people/mu.png' alt='mu' />
                </div>
                <div>
                  <StaticImage src='../images/contest/people/gaofeng.png' alt='gaofeng' />
                </div>
                <div>
                  <StaticImage src='../images/contest/people/ben.png' alt='ben' />
                </div>
                <div>
                  <StaticImage src='../images/contest/people/xu.png' alt='xu' />
                </div>
                <div>
                  <StaticImage src='../images/contest/people/zhou.png' alt='zhou' />
                </div>
              </PeoplePictureWrapper>
            </MainJudgeWrapper>

            <MainJudgeWrapper pcBottom={81} mobileBottom={78}>
              <section>
                <p>专家委员会</p>
                {/* <p>委员会</p> */}
              </section>
              <PeoplePictureWrapper>
                <div>
                  <StaticImage src='../images/contest/people/shi.png' alt='shi' />
                </div>
                <div>
                  <StaticImage src='../images/contest/people/wei.png' alt='wei' />
                </div>
                <div>
                  <StaticImage src='../images/contest/people/peng.png' alt='peng' />
                </div>
                <div>
                  <StaticImage src='../images/contest/people/sun.png' alt='sun' />
                </div>
              </PeoplePictureWrapper>
            </MainJudgeWrapper>
            <VideoWrapper>
              <LeftVideoContainer>
                {/* eslint-disable-next-line */}
                <video controls preload='auto' poster={poster}>
                  <source type='video/mp4' src={rrcMp4} rel='nofollow' />
                </video>
              </LeftVideoContainer>
              <RightContentWrapper className='video-pc-text'>
                <p>用7秒钟展现精彩小世界</p>
                <p>1. 以大赛发放的3D动画模板场景（场景格式为FBX或USD）为基础，进行创意制作。</p>
                <p>2. 作品以一个圆形翻板作为平台，在平台上摆放任意内容，相机围绕圆形平台环绕旋</p>
                <p>转。翻板从翻上来、展示、到翻下去，总计时长7秒整。参赛者可根据这个3D动画模</p>
                <p>板，大开脑洞，大胆创作；</p>
                <p>3. 作品画面风格不限，允许写实、卡通、水墨……只要能依靠实时引擎即时呈现出来</p>
                <p>3的任何画风，都可接受；</p>
                <p>4. 题材遵守“四不原则”：不违法，不三俗，不侵权，不惹事（不违反中国法律；不</p>
                <p>庸俗、低俗、媚俗；不侵犯他人合法权益；不涉及意识形态等争议话题）。</p>
                <Download3DTemplateBtn
                  top={20}
                  download='实时渲染大赛-赛事资料.rar'
                  nofollow
                  href='/contest-material.rar'
                >
                  <DownloadIcon />
                  3D模板下载
                </Download3DTemplateBtn>
              </RightContentWrapper>
              <RightContentWrapper className='video-mobile-text'>
                <p>用7秒钟展现精彩小世界</p>
                <p>1. 以大赛发放的3D动画模板场景（场景格式为FBX或USD）为基础，进行</p>
                <p>创意制作。2. 作品以一个圆形翻板作为平台，在平台上摆放任意内容，相</p>
                <p>机围绕圆形平台环绕旋转。翻板从翻上来、展示、到翻下去，总计时长7</p>
                <p>秒整。参赛者可根据这个3D动画模板，大开脑洞，大胆创作；</p>
                <p>3. 作品画面风格不限，允许写实、卡通、水墨……只要能依靠实时引擎即</p>
                <p>时呈现出来3的任何画风，都可接受；</p>
                <p>4. 题材遵守“四不原则”：不违法，不三俗，不侵权，不惹事（不违反中</p>
                <p>国法律；不庸俗、低俗、媚俗；不侵犯他人合法权益；不涉及意识形态等</p>
                <p>争议话题）。</p>
                <Download3DTemplateBtn
                  top={20}
                  download='实时渲染大赛-赛事资料.rar'
                  nofollow
                  href='/contest-material.rar'
                >
                  <DownloadIcon />
                  3D模板下载
                </Download3DTemplateBtn>
              </RightContentWrapper>
            </VideoWrapper>

            <BottomInfoWrapper id='event-information'>
              <BottomInfoMenuWrapper>
                {bottomInfoItems.map((item) => (
                  <BottomInfoItem
                    key={item.value}
                    isActive={bottomInfoItem === item.value}
                    onClick={() => setBottomInfoItem(item.value)}
                    onMouseEnter={() => setBottomInfoItem(item.value)}
                  >
                    {item.title}
                  </BottomInfoItem>
                ))}
              </BottomInfoMenuWrapper>
              {bottomInfoItem === 'base' && (
                <BottomInfoContentWrapper>
                  <BottomInfoContentItem style={{ width: 400 }}>
                    <p>技术性</p>
                    <p>
                      CG软件使用专业、熟练，模型、材质、渲染等制
                      <span className='bottom-pc'>作质量优秀</span>
                    </p>
                    <p className='bottom-mobile'>作质量优秀</p>
                  </BottomInfoContentItem>
                  <BottomInfoContentItem style={{ width: 360 }}>
                    <p>创意性</p>
                    <p>作品富有新意和想象力，给人耳目一新的视觉感受</p>
                  </BottomInfoContentItem>
                  <BottomInfoContentItem style={{ width: 400 }}>
                    <p>艺术性</p>
                    <p>
                      画面表达流畅，视觉效果突出，整体设计具有美感
                      <span className='bottom-pc'>和艺术价值</span>
                    </p>
                    <p className='bottom-mobile'>和艺术价值</p>
                  </BottomInfoContentItem>
                  <BottomInfoContentItem style={{ width: 360 }}>
                    <p>交互性（加分项）</p>
                    <p>作品如具有可交互性，可根据交互效果给予加分</p>
                  </BottomInfoContentItem>
                </BottomInfoContentWrapper>
              )}

              {bottomInfoItem === 'support' && (
                <BottomInfoContentWrapper height='100%'>
                  <BottomInfoContentItem height={140} mobileHeight={160} style={{ width: 420 }}>
                    <p>英伟达</p>
                    <p>大赛全程为参赛者提供Omniverse使用指导</p>
                    <section>
                      <DownloadLink
                        width={180}
                        href='https://www.nvidia.cn/omniverse/'
                        target='_blank'
                      >
                        <DownloadIcon
                          style={{
                            position: 'relative',
                            top: 4,
                            right: 6,
                          }}
                        />
                        Omniverse下载
                      </DownloadLink>
                      <DownloadLink
                        target='_blank'
                        href='https://www.bilibili.com/video/BV1zb4y1B7JM?spm_id_from=333.999.0.0'
                      >
                        Omniverse使用教程
                      </DownloadLink>
                    </section>
                  </BottomInfoContentItem>
                  <BottomInfoContentItem height={140} mobileHeight={180} style={{ width: 340 }}>
                    <p>万生华态</p>
                    <p style={{ lineHeight: 1 }}>参赛者可免费使用万生华态数字资产进行创作</p>
                    <p className='note-text'>（报名参赛后可免费使用数字资产）</p>
                    <section>
                      <DownloadLink width={180} href='http://pbrmax.com' target='_blank'>
                        PBRMAX数字资产库
                        <DownloadIcon
                          style={{
                            transform: 'rotateZ(-90deg)',
                            position: 'relative',
                            top: 4,
                            left: 4,
                          }}
                        />
                      </DownloadLink>
                      <DownloadLink href='https://t.1yb.co/HiVc'>使用教程</DownloadLink>
                    </section>
                  </BottomInfoContentItem>
                  <BottomInfoContentItem height={140} mobileHeight={200} style={{ width: 420 }}>
                    <p>青椒云</p>
                    <p style={{ lineHeight: 1.2 }}>
                      参赛者可免费使用装载有Omniverse的
                      <span className='bottom-pc'>RTX高配版青椒云桌面半个月</span>
                    </p>
                    <p className='bottom-mobile'>RTX高配版青椒云桌面半个月</p>
                    <p className='note-text'>（报名参赛后可申请免费使用，数量有限，先申请先得）</p>
                    <section>
                      <DownloadLink
                        width={180}
                        target='_blank'
                        href='https://www.qingjiaocloud.com/download/'
                      >
                        <DownloadIcon
                          style={{
                            position: 'relative',
                            top: 4,
                            right: 6,
                          }}
                        />
                        青椒云下载
                      </DownloadLink>
                      <DownloadLink target='_blank' href='https://www.qingjiaocloud.com/'>
                        了解青椒云
                        <DownloadIcon
                          style={{
                            transform: 'rotateZ(-90deg)',
                            position: 'relative',
                            top: 4,
                            left: 4,
                          }}
                        />
                      </DownloadLink>
                    </section>
                  </BottomInfoContentItem>
                  <BottomInfoContentItem height={140} mobileHeight={140} style={{ width: 340 }}>
                    <p>3DCAT</p>
                    <p style={{ lineHeight: 1.2 }}>
                      3DCAT实时渲染云平台为参赛作品免费提供
                      <span className='bottom-pc'>实时云渲染的展示平台</span>
                    </p>
                    <p className='bottom-mobile'>实时云渲染的展示平台</p>
                    <section>
                      <DownloadLink width={180} href='https://www.3dcat.live/' target='_blank'>
                        3DCAT介绍
                        <DownloadIcon
                          style={{
                            transform: 'rotateZ(-90deg)',
                            position: 'relative',
                            top: 4,
                            left: 4,
                          }}
                        />
                      </DownloadLink>
                    </section>
                  </BottomInfoContentItem>
                </BottomInfoContentWrapper>
              )}

              {bottomInfoItem === 'communicate' && (
                <BottomInfoContentWrapper single>
                  <BottomInfoContentItem>
                    <p>赛事交流</p>
                    <p>想跟技术大咖们学习CG技巧吗？</p>
                    <p> 想和CG小伙伴们交流创作经验吗？</p>
                    <p> 欢迎加入我们的赛事交流群，一起玩转创意！</p>
                    <p>
                      如有任何关于赛事的疑问，也可以联系大赛
                      <span className='bottom-pc'>主办工作人员邮箱：RRC@rayvision.com</span>
                    </p>
                    <p className='bottom-mobile'>主办工作人员邮箱：RRC@rayvision.com</p>
                    <div className='bottom-code'>
                      <div>
                        <StaticImage
                          src='../images/contest/code.jpeg'
                          alt='code'
                          width={100}
                          height={100}
                        />
                        <p>扫码添加小助手微信</p>
                      </div>
                      <div>
                        <p>或微信添加小助手</p>
                        <p>微信：RealTime_3DCAT</p>
                        <p>备注“实时渲染大赛”</p>
                      </div>
                    </div>
                  </BottomInfoContentItem>
                </BottomInfoContentWrapper>
              )}

              {bottomInfoItem === 'notify' && (
                <BottomInfoContentWrapper single>
                  <BottomInfoContentItem>
                    <p>获奖通知</p>
                    <p>
                      1. 获奖通知将在最终结果公示后7个工作日内
                      <span className='bottom-pc'>
                        通过邮件方式发送至获奖作者参赛报名时预留的邮箱地址，请确认提供真实有效的联系方式。
                      </span>
                    </p>
                    <p className='bottom-mobile'>通过邮件方式发送至获奖作者参赛报名时预留</p>
                    <p className='bottom-mobile'>的邮箱地址，请确认提供真实有效的联系方式。</p>
                    <p>
                      2. 获奖作者在活动结束后请注意查收邮件，
                      <span className='bottom-pc'>
                        并根据邮件提示内容及时配合主办方完成作品收集及奖品发放等工作。
                      </span>
                    </p>
                    <p className='bottom-mobile'>并根据邮件提示内容及时配合主办方完成作品</p>
                    <p className='bottom-mobile'>收集及奖品发放等工作。</p>
                    <p>
                      3. 若获奖作者在限定时间内对获奖通知未做
                      <span className='bottom-pc'>回应，则视为放弃奖项，由其他参赛作者取代。</span>
                    </p>
                    <p className='bottom-mobile'>回应，则视为放弃奖项，由其他参赛作者取代。</p>
                    <p>
                      4. 主办方将在比赛结束后安排奖品发放事宜，
                      <span className='bottom-pc'>
                        同一获奖作者可获得多个奖项（人气奖、特别奖）。
                      </span>
                    </p>
                    <p className='bottom-mobile'>同一获奖作者可获得多个奖项（人气奖、特别奖）。</p>
                    <p>
                      5. 最终赛果将在主办网站、协办网站等平台
                      <span className='bottom-pc'>进行公示，并通过各媒体渠道进行曝光宣传。</span>
                    </p>
                    <p className='bottom-mobile'>进行公示，并通过各媒体渠道进行曝光宣传。</p>
                  </BottomInfoContentItem>
                </BottomInfoContentWrapper>
              )}
              {bottomInfoItem === 'right' && (
                <BottomInfoContentWrapper single>
                  <BottomInfoContentItem>
                    <p>相关权益</p>
                    <p>
                      1. 所有参赛者向大赛提交的参赛作品需拥有独
                      立完整的著作权，或已经取得著作权人的合法授权。
                    </p>
                    <p>
                      2. 主办方及合作方拥有对全部参赛作品进行展
                      示、报道、宣传及用于市场活动的权利，参赛者保留对其作品的著作权。主办方及合作方
                    </p>
                    <p>有权将用于市场活动的作品加入赛事水印。 </p>
                    <p>
                      3.
                      作品一经提交，将视为参赛者同意并遵守比赛相关规定，若作品在商业使用中其著作权存在争议，主办方不承担因作品侵犯他人(或单
                    </p>
                    <p></p>
                    <p>位)的权利而产生的法律责任，由提供作品的参赛者承担全部法律责任。</p>
                    <p>
                      4.
                      对存在争议的作品，大设计奖组委会有权保留甚至撤销该作品的参赛资格。如获奖作品存在知识产权等争议，大设计奖组委会有权撤
                    </p>
                    <p>销获奖资格，召回奖状、奖杯、奖品等荣誉。</p>
                    <p>5. 本次大赛最终解释权归主办方瑞云科技所有。</p>
                  </BottomInfoContentItem>
                </BottomInfoContentWrapper>
              )}
            </BottomInfoWrapper>
            <MiddleButtonWrapper>
              <MiddleButtonLink href='#form-submit'>报名参赛</MiddleButtonLink>
              <MiddleButtonLink target='_blank' href='/contest-material.rar'>
                下载大赛资料
              </MiddleButtonLink>
            </MiddleButtonWrapper>
            <BottomInfoWrapper style={{ marginBottom: 160, height: 'auto' }}>
              <DesignIntroTitle>
                <p>设计说明</p>
              </DesignIntroTitle>
              <BottomInfoMenuWrapper>
                {designIntroduction.map((item) => (
                  <BottomInfoItem
                    key={item.value}
                    isActive={designIntro === item.value}
                    onClick={() => setDesignIntro(item.value)}
                    onMouseEnter={() => setDesignIntro(item.value)}
                  >
                    {item.title}
                  </BottomInfoItem>
                ))}
              </BottomInfoMenuWrapper>

              {designIntro === 'know' && (
                <BottomInfoContentWrapper single height='400px'>
                  <BottomInfoContentItem>
                    <p>参赛须知</p>
                    <p>1.本次参赛作品必须使用实时渲染引擎进行制作；</p>
                    <p>
                      2.参赛请先填写报名表，并下载大赛资料获取3D动画模板及OV试用版，以模板为基础进行创作；
                    </p>
                    <p>
                      3.本次大赛由青椒云提供云桌面服务及技术支持，万生华态提供PBRMAX数字资产支持，3DCAT提供实时云渲染服务支持；
                    </p>
                    <p>
                      4.成功报名后，参赛者会收到参赛邮件，邮件内将免费提供青椒云及万生华态账号，参赛者可按需使用青椒云桌面及万生华态的数字资产进行作品创作；
                    </p>
                    <p>
                      5.
                      作品提交：需要按照参赛邮件内的要求提交作品，作品命名格式为“作品名_作者名”，作者名务必要与报名时填写的姓名一致。
                    </p>
                  </BottomInfoContentItem>
                </BottomInfoContentWrapper>
              )}

              {designIntro === 'commit' && (
                <BottomInfoContentWrapper single height='400px'>
                  <BottomInfoContentItem>
                    <p>作品提交内容</p>
                    <p>每位参赛者的投稿作品须包含2部分内容：实时Demo与动画视频</p>
                    <p>实时Demo：</p>
                    <p>
                      1) 参赛作品的本体，须使用3D实时引擎制作（如Omniverse，Unreal
                      Engine，Unity3D，Twinmotion，D5，Marmoset Toolbag等，
                    </p>
                    <p>
                      2)
                      引擎如果支持打包封装exe，则提交exe版本软件包；如不支持，则提交制作过程的录屏视频；
                    </p>
                    <p>
                      3)
                      制作过程可使用其他资产生产软件，如贴图绘制、建模、动画等，但所有资产最终必须整合进引擎，用引擎实时渲染方式来呈现；
                    </p>
                    <p>
                      4) exe软件包版本的发布平台须为windows10
                      x64，如有Pre-request必须自带安装包；硬件平台必须使用NVIDIA系列显卡
                    </p>
                    <p>
                      5) 作品可包含交互功能，巧妙的交互可作为加分项，但交互形式仅限于鼠标键盘操作；
                    </p>
                    <p>6) 作品可包含音效，但不要使用大段背景音乐；</p>
                    <p>7) 交互程序请以1920*1080分辨率全屏运行；</p>
                    <p>8) 建议将整个作品动画做成循环，便于长时间连续循环播放。</p>
                    <p>动画视频：</p>
                    <p>1) 视频须使用FBX或USD内提供的环绕相机视角渲染或录制；</p>
                    <p>
                      2) 请以1920*1080 @ 60fps录制高清画面，总长7秒整（总共60fps x 7s = 420帧）；
                    </p>
                    <p>3) 可包含引擎内的实时音效；</p>
                    <p>4) 画面中应只含有作品本身画面，注意不要出现不相干的系统界面或水印；</p>
                    <p>5) 不要做黑场、白场、渐入；</p>
                    <p>
                      6)录制视频必须是引擎直接渲染或录屏的画面，不得经过任何后期软件二次加工、合成、剪辑。
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </BottomInfoContentItem>
                </BottomInfoContentWrapper>
              )}

              {designIntro === 'scene' && (
                <BottomInfoContentWrapper single height='400px'>
                  <BottomInfoContentItem>
                    <p>3D场景规格</p>
                    <p>1.请以所提供的FBX或USD，以及大赛提供的范例视频为主要参考标准；</p>
                    <p>
                      2.场景整体尺度可根据自己创意需要进行整体缩放，但翻板动画、相机动画、相机fov必须保持不变，保证作品最终构图与画面比例要和原FBX或USD一致；
                    </p>
                    <p>
                      3.翻板平台作为主要展示平台，可在其上放置主要表现对象，但要注意在翻板翻转时不能穿帮，加上动画吧！
                    </p>
                    <p>4. 平台四周的地面可任意改动发挥。</p>
                  </BottomInfoContentItem>
                </BottomInfoContentWrapper>
              )}
            </BottomInfoWrapper>

            <BottomInfoWrapper
              id='qa'
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 200,
                paddingTop: 70,
                height: 'auto',
              }}
            >
              <DesignIntroTitle>
                <p>常见问题</p>
              </DesignIntroTitle>
              <QuestionWrapper>
                <QuestionItem index={0}>
                  <p>Question1：参赛需要收费吗？</p>
                  <p>Answer：不需要。</p>
                </QuestionItem>
                <QuestionItem index={1}>
                  <p>Question2：一个人/团队可以报多少个组别？可以提交多少个作品？</p>
                  <p>
                    Answer：每人/每个团队仅可报名一个组别参赛，且只允许提交一个作品，不允许一个人/团队报名多个组别。
                  </p>
                </QuestionItem>
                <QuestionItem index={2}>
                  <p>Question3：一个人/团队可以获得多个奖项吗？</p>
                  <p>
                    Answer：可以，除了获得各自组别的奖项外，仍有资格获得最佳人气奖和万生华态特别奖。
                  </p>
                </QuestionItem>
                <QuestionItem index={3}>
                  <p>Question4：如何提交作品？</p>
                  <p>
                    Answer：参赛请务必先报名，报名成功后将收到参赛邮件，按照邮件内的要求提交作品即可。作品命名格式为“作品名_作者名”，作者名务必要与报名时填写的姓名一致。
                  </p>
                </QuestionItem>
                <QuestionItem index={4}>
                  <p>Question5：最佳人气奖将如何评选？</p>
                  <p>
                    Answer：最终入围作品将于比赛截至后的7天内公布，入围作品将进行网络公开投票，得票最高者将获得最佳人气奖。入围作品公布及投票的具体安排，请关注3DCAT微信公众号“3DCAT实时渲染云”。
                  </p>
                </QuestionItem>
                <QuestionItem index={5}>
                  <p>Question6：万生华态特别奖将如何评选？</p>
                  <p>
                    Answer：参赛者使用了万生华态数字资产创作参赛作品，即有机会获得万生华态提供的特别奖，获奖者由万生华态根据赛事评审规则进行评选。
                  </p>
                </QuestionItem>
                <QuestionItem index={6}>
                  <p>Question7：比赛期间，我是否可以将参赛作品发布到互联网平台？</p>
                  <p>
                    Answer：可以，发布时请在作品内标明该作品为“新锐先锋，玩转未来——实时渲染3D动画创作大赛参赛作品”，并@瑞云官方账号“Renderbus瑞云渲染”（微博/B站/抖音/视频号）
                  </p>
                </QuestionItem>
                <QuestionItem index={7}>
                  <p>Question8：获奖后将如何通知？</p>
                  <p>
                    Answer：获奖通知将在最终结果公示后7个工作日内通过邮件方式发送至获奖作者参赛报名时预留的邮箱地址，请确认提供真实有效的联系方式。
                  </p>
                </QuestionItem>
                <QuestionItem index={8}>
                  <p>Question9：如何联系大赛组委会</p>
                  <p>
                    Answer：可联系邮箱：RRC@rayvision.com
                    或者添加微信：RealTime_3DCAT，工作人员奖尽快与您取得联系。
                  </p>
                </QuestionItem>
                <QuestionItem index={9}>
                  <p>Question10：如何获得大赛的技术支持？</p>
                  <p>
                    Answer：可加入我们的赛事交流微信群，群内有相关的技术人员进行技术相关的指导和答疑。
                  </p>
                </QuestionItem>
                <QuestionItem index={10}>
                  <p>Question11：如何关注大赛的最新动态？</p>
                  <p>
                    Answer：请关注3DCAT微信公众号“3DCAT实时渲染云”，我们将在公众号中随时公布大赛的最新动态。
                  </p>
                </QuestionItem>
              </QuestionWrapper>
            </BottomInfoWrapper>

            <BottomFormWrapper id='form-submit' style={{ marginBottom: 100 }}>
              <FormSubmit />
            </BottomFormWrapper>
          </BannerContentWrapper>
        </BannerWrapper>
      </PageContainer>
      <GlobalStyle />
    </>
  )
}

export default RenderContest
