import styled, { css } from 'styled-components'
import { transition, Media } from '../../style/theme'

export const GoldBorderFromBottomToTop = css`
  border: 1px solid transparent;
  border-image: linear-gradient(0deg, #caa577, #000) 10 10;
  background: linear-gradient(0deg, #2e2f30 0%, rgba(68, 68, 68, 0.3) 100%);
  border-top: none;
`

export const GoldBorderFromTopToBottom = css`
  border: 1px solid transparent;
  border-image: linear-gradient(180deg, #caa577, #000) 10 10;
  background: linear-gradient(180deg, #2e2f30 0%, rgba(68, 68, 68, 0.3) 100%);
  border-bottom: none;
`

export const GoldBorderFromLeftToRight = css`
  border: 1px solid transparent;
  border-image: linear-gradient(90deg, #caa577, #000) 10 10;
  background: linear-gradient(90deg, #2e2f30 0%, transparent 100%);
  border-right: none;
`

export const ExibitionWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 600px;
  box-sizing: border-box;
  position: relative;
  margin-top: 130px;
  padding: 60px 0 0 0;
  ${GoldBorderFromBottomToTop};
  display: flex;
  ${Media.phone`
    display: block;
    padding: 0;
    height: auto;
  `};
`

export const TabWrapper = styled.div`
  position: absolute;
  display: flex;
  column-gap: 9px;
  top: -50px;
  left: calc((100% - 738px) / 2);
  ${Media.phone`
    left: calc((100% - 318px) / 2);
  `};
`

export const Tab = styled.p`
  width: 240px;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  color: #cccccc;
  background: linear-gradient(0deg, rgba(68, 68, 68, 0.19) 0%, rgba(102, 102, 102, 0.8) 100%);
  transition: ${transition('all')};
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  ${({ isActive }) =>
    isActive &&
    css`
      background: linear-gradient(0deg, rgba(68, 45, 30, 0.6) 0%, rgba(195, 149, 118, 0.8) 100%);
      height: 60px;
      line-height: 60px;
      transform: translateY(-10px);
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    `};
  ${Media.phone`
    width: 100px;
  `};
`

export const MenusWrapper = styled.div`
  width: 200px;
  ${Media.phone`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  `};
`

export const MenuItem = styled.p`
  width: 100%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  color: #ccc;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  ${({ isActive }) =>
    isActive &&
    css`
      ${GoldBorderFromLeftToRight};
      color: #caa577;
      font-size: 18px;
      font-weight: bold;
    `};
  ${Media.phone`
    width: auto;
    padding: 0 5px;
    font-size: 12px;
    ${({ isActive }) =>
      isActive &&
      css`
        ${GoldBorderFromTopToBottom};
      `}; 
  `};
`

export const ContentWrapper = styled.div`
  width: calc(100% - 200px);
  ${Media.phone`
    width: 100%;
  `};
`
