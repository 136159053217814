import styled from 'styled-components'
import { Media } from '../../../../style/theme'

export const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 38px 42px 38px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  ${Media.phone`
    padding: 0;
  `};
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 80px;
  transition: all 0.5s;
  flex-shrink: 0;
  transform: ${({ curIndex }) => `translateX(calc(-${curIndex} * 100%))`};
  video {
    height: 420px;
    margin-bottom: 20px;
    object-fit: fill;
    ${Media.phone`
      height: 300px;
      width: 100%;
    `};
  }
  ${Media.phone`
    padding: 5px;
    width: 100%;
  `};
`

export const ArrowButton = styled.div`
  width: 50px;
  height: 100px;
  position: absolute;
  background: #0f0a07;
  top: 140px;
  display: ${({ disable }) => (disable ? 'none' : 'block')};
  cursor: pointer;
  ${Media.phone`
    opacity: 0.3;
    top: 85px;
  `};
`

export const ArrowLeftButton = styled(ArrowButton)`
  left: 38px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 3px solid #646262;
    top: 32px;
    left: 18px;
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotateZ(45deg);
  }
  &:hover {
    &:after {
      border: 3px solid #caa577;
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }
  ${Media.phone`
    left: 6px;
  `};
`

export const ArrowRightButton = styled(ArrowButton)`
  right: 38px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 3px solid #646262;
    top: 32px;
    right: 18px;
    border-left-color: transparent;
    border-bottom-color: transparent;
    transform: rotateZ(45deg);
  }
  &:hover {
    &:after {
      border: 3px solid #caa577;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }
  ${Media.phone`
    right: 6px;
  `};
`

export const BottomContentWrapper = styled.div`
  display: flex;
  column-gap: 188px;
  > div:nth-child(1) {
    white-space: nowrap;
  }
  p {
    color: #eee;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
  }
  a {
    color: #348ef5;
  }
  ${Media.phone`
    flex-wrap: wrap;
  `};
`
export const PassageWrapper = styled.p``
