import styled, { css } from 'styled-components'

export const RadioWrap = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
  column-gap: 6px;
  margin: 0;
  cursor: pointer;
`

export const InputStyled = styled.input`
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const StyledRadio = styled.div`
  ${({ checked }) => css`
    width: 18px;
    height: 18px;
    position: relative;
    border: 1px solid ${checked ? '#c1a283' : '#999999'};
    border-radius: 50%;
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background-color: ${checked && '#c1a283'};
      left: calc((100% - 10px) / 2);
      top: calc((100% - 10px) / 2);
    }
  `};
`

export const LabelText = styled.label`
  color: #999999;
`

export const RadioGroupWrap = styled.div`
  display: inline-flex;
  column-gap: 10px;
`
