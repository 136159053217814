import React from 'react'
import { InputStyled, RadioWrap, LabelText, StyledRadio } from './radio.styles'

const Radio = ({ value, name, checked, disabled, onChange, children }) => {
  const [handleCheck, sethandleCheck] = React.useState(false)

  React.useEffect(() => {
    sethandleCheck(!!checked)
    // eslint-disable-next-line
  }, [])
  React.useEffect(() => {
    sethandleCheck(!!checked)
  }, [checked])
  const handleChange = React.useCallback(
    (checked) => {
      sethandleCheck(checked)
      value && onChange?.(value)
    },
    // eslint-disable-next-line
    [onChange],
  )

  return (
    <RadioWrap>
      <InputStyled
        type='radio'
        value={value}
        name={name}
        onChange={(e) => handleChange(e.target.checked)}
        disabled={disabled}
        checked={handleCheck}
      ></InputStyled>
      <StyledRadio checked={handleCheck} disabled={disabled} />
      <LabelText disabled={disabled}>{children}</LabelText>
    </RadioWrap>
  )
}

export default Radio
