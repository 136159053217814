import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby-plugin-react-intl'
import {
  CarouselWrapper,
  ContentWrapper,
  ArrowLeftButton,
  ArrowRightButton,
  BottomContentWrapper,
  PassageWrapper,
} from './atoms'
import { Video } from '../video'

export function Carousel({ content }) {
  const [curIndex, setCurIndex] = useState(0)

  useEffect(() => {
    setCurIndex(0)
  }, [content])

  return (
    <CarouselWrapper>
      {content.map((c, i) => (
        <ContentWrapper key={i} curIndex={curIndex}>
          <Video key={c.link} src={c.link} />
          <BottomContentWrapper>
            <div>
              <p>作品：{c.name}</p>
              <p>作者：{c.author}</p>
            </div>
            <div>
              {c.passage && (
                <PassageWrapper>
                  专访文章：
                  <Link to={c.passage.link} target='_blank'>
                    {c.passage.text}
                  </Link>
                </PassageWrapper>
              )}
            </div>
          </BottomContentWrapper>
        </ContentWrapper>
      ))}
      {content.length > 1 && (
        <>
          <ArrowLeftButton
            onClick={() => curIndex > 0 && setCurIndex((c) => c - 1)}
            disable={curIndex <= 0}
          />
          <ArrowRightButton
            onClick={() => curIndex < content.length - 1 && setCurIndex((c) => c + 1)}
            disable={curIndex >= content.length - 1}
          />
        </>
      )}
    </CarouselWrapper>
  )
}
