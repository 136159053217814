import React, { useRef } from 'react'

export function Video({ src }) {
  const videoRef = useRef(null)

  React.useEffect(() => {
    videoRef.current?.addEventListener('loadeddata', () => {
      videoRef.current?.play()
    })
  }, [])

  return (
    <>
      {/*eslint-disable-next-line*/}
      <video
        key={src}
        ref={videoRef}
        playsInline
        controls
        muted
        loop
        preload='metadata'
        width={'100%'}
      >
        <source muted loop type='video/mp4' rel='nofollow' width={'100%'} src={src} />
      </video>
    </>
  )
}
