/* eslint-disable */
import React, { useState } from 'react'
import axios from 'axios'
import { StaticImage } from 'gatsby-plugin-image'
import {
  FormWrapper,
  Title,
  FormContentWrapper,
  Row,
  FormItem,
  StyledInput,
  SubmitButton,
  Link,
  Span,
  CodeWrapper,
} from './form-submit.atoms'
import Radio from './components/radio'
import Checkbox from './components/checkbox'
import { whereToKnowThisContest, hasPlanToBuyGPU, jobs, works } from './utils'

const ddl = 1646927999000

export const FormSubmit = () => {
  const [needOmn, setNeedOmn] = useState(true)
  const [whereToKnow, setWhereToKnow] = useState('')
  const [hasPlanToBuy, setHasPlanToBuy] = useState('')
  const [hasBudget, setHasBudget] = useState('是')
  const [wannaRegister, setWannaRegister] = useState('是')
  const [wannaConnect, setWannaConnect] = useState('是')
  const [connectWithDellSalers, setConnectWithDellSalers] = useState('是')
  const [isSubscribe, setSubscribe] = useState(false)
  const [agreeSurvey, setAgreeSurvey] = useState(false)
  const [agreeInfoSubmit, setAgreeInfoSubmit] = useState(false)
  const [getAuthorize, setGetAuthorize] = useState(false)
  const [formData, setFormData] = useState({})

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [formFinish, setFormFinished] = useState(false)

  const formRef = React.useRef(null)

  const isExpired = Math.ceil((ddl - new Date().getTime()) / 24 / 3600 / 1000) < 0

  const submit = (e) => {
    e.preventDefault()

    const html = `
    <!DOCTYPE html>
      <html>
        <head>
          <style>
            table {
              font-family: Source Han Sans CN, Source Han Sans SC,arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }
            th {
              background: #dddddd;
            }
            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
              white-space: pre-wrap;
            }
          </style>
        </head>
        <body>
          <table style="borderCollapse: 'collapse'">
            <tr>
              <th>姓名</th>
              <th>电话</th>
              <th>邮箱</th>
              <th>微信号</th>
              <th>公司/学校</th>
              <th>职务</th>
              <th>行业</th>
              <th>是否需要使用ov青椒云</th>
              <th>使用ov青椒云的时间</th>
              <th>获知渠道</th>
              <th>是否有采购或升级显卡计划</th>
              <th>采购计划是否已经取得预算</th>
              <th>是否愿意注册下载最新NVIDIA ov</th>
              <th>是否愿意与NVIDIA联系获得技术帮助</th>
              <th>是否愿意与戴尔科技销售联系，了解更多工作站产品及渲染方案？</th>
              <th>向我推送NVIDIA相关动态</th>
              <th>知悉且同意 NVIDIA Corporation</th>
              <th>知悉且同意信息会被传输到位于美国的 NVIDIA Corporation</th>
              <th>拥有独立完整的著作权</th>
            </tr>
            <tr>
              <td>${formData.name}</td>
              <td>${formData.phone}</td>
              <td>${formData.email}</td>
              <td>${formData.wechat}</td>
              <td>${formData.schoolAndCompany}</td>
              <td>${formData.job}</td>
              <td>${formData.work}</td>
              <td>${formData ? '需要' : '不需要'}</td>
              <td>${formData.fromYear}年${formData.fromMonth}月 - ${formData.toYear}年${
      formData.toMonth
    }月</td>
              <td>${whereToKnow},其他：${formData.other || '-'}</td>
              <td>${hasPlanToBuy}</td>
              <td>${hasBudget}</td>
              <td>${wannaRegister}</td>
              <td>${wannaConnect}</td>
              <td>${connectWithDellSalers}</td>
              <td>${isSubscribe ? '是的' : '否'}</td>
              <td>${agreeInfoSubmit ? '是的' : '否'}</td>
              <td>${agreeSurvey ? '是的' : '否'}</td>
              <td>${getAuthorize ? '是的' : '否'}</td>
            </tr>
          </table>
        </body>
      </html>
    `

    setSubmiting(true)
    axios({
      url: 'https://www.foxrenderfarm.com/service/email/send',
      method: 'POST',
      data: { subject: 'rrc报名表', to: 'rrc@rayvision.com', html },
    })
      .then((res) => {
        if (res.status === 200) {
          setDisableSubmit(true)
          alert('提交成功！')
        }
      })
      .finally(() => setSubmiting(false))
  }

  const setFormDataWhenHandling = () => {
    const formData = new FormData(formRef.current)
    const params = {}
    for (const pair of formData.entries()) {
      params[pair[0]] = pair[1]
    }
    setFormData(params)
    setFormFinished(formCanBeSubmit(params, needOmn))
  }

  const formCanBeSubmit = (formData, needOmn) => {
    if (needOmn) {
      if (!formData.fromYear || !formData.fromMonth || !formData.toYear || !formData.toMonth) {
        return false
      }
    }

    return (
      !!formData.name &&
      !!formData.phone &&
      !!formData.email &&
      !!formData.wechat &&
      !!formData.schoolAndCompany &&
      !!formData.job &&
      !!formData.work
    )
  }

  return (
    <FormWrapper>
      <Title>大赛报名表</Title>
      <FormContentWrapper onSubmit={submit} name='form' ref={formRef} autoComplete='off'>
        <Row>
          <FormItem>
            <p style={{ whiteSpace: 'nowrap' }}>您的名字</p>
            <StyledInput name='name' onChange={() => setFormDataWhenHandling()} />
          </FormItem>
          <FormItem>
            <p style={{ whiteSpace: 'nowrap' }}>您的电话</p>
            <StyledInput name='phone' onChange={() => setFormDataWhenHandling()} />
          </FormItem>
        </Row>
        <Row>
          <FormItem>
            <p style={{ whiteSpace: 'nowrap' }}>您的邮箱</p>
            <StyledInput name='email' onChange={() => setFormDataWhenHandling()} />
          </FormItem>
          <FormItem>
            <p style={{ whiteSpace: 'nowrap' }}>您的微信号</p>
            <StyledInput name='wechat' onChange={() => setFormDataWhenHandling()} />
          </FormItem>
        </Row>

        <Row>
          <FormItem>
            <p style={{ whiteSpace: 'nowrap' }}>您的公司/学校</p>
            <StyledInput name='schoolAndCompany' onChange={() => setFormDataWhenHandling()} />
          </FormItem>
        </Row>
        <Row>
          <FormItem>
            <p style={{ whiteSpace: 'nowrap' }}>您所担任的职务</p>
            <select name='job' onChange={() => setFormDataWhenHandling()}>
              <option></option>
              {jobs.map((item) => (
                <option key={item}>{item}</option>
              ))}
            </select>
          </FormItem>
        </Row>
        <Row>
          <FormItem>
            <p style={{ whiteSpace: 'nowrap' }}>您所在的行业</p>
            <select name='work' onChange={() => setFormDataWhenHandling()}>
              <option></option>
              {works.map((item) => (
                <option key={item}>{item}</option>
              ))}
            </select>
          </FormItem>
        </Row>
        <Row>
          <section>
            <p>
              <strong>* </strong>
              本次参赛是否需要使用搭载Omniverse的高配青椒云桌面（青椒云为参赛者提供装载有Omniverse的
            </p>
            <p>RTX高配版青椒云桌面半个月使用时间）</p>
          </section>
        </Row>
        <Row column rowGap='6px'>
          <FormItem>
            <section>
              <Radio
                value='need'
                checked={needOmn}
                onChange={() => {
                  setNeedOmn(true)
                  setFormFinished(formCanBeSubmit(formData, true))
                }}
              >
                <Span>需要</Span>
              </Radio>
            </section>
          </FormItem>
          <FormItem>
            <section>
              <Radio
                value='inNeed'
                checked={!needOmn}
                onChange={() => {
                  setNeedOmn(false)
                  setFormFinished(formCanBeSubmit(formData, false))
                }}
              >
                <Span>不需要</Span>
              </Radio>
            </section>
          </FormItem>
        </Row>
        {needOmn && (
          <Row column rowGap='6px'>
            <FormItem>
              <p>使用搭载Omniverse的高配青椒云桌面的时间</p>
            </FormItem>
            <FormItem>
              <p>
                <StyledInput
                  lineInput
                  width={80}
                  name='fromYear'
                  onChange={() => setFormDataWhenHandling()}
                />
                年
                <StyledInput
                  lineInput
                  width={80}
                  name='fromMonth'
                  onChange={() => setFormDataWhenHandling()}
                />{' '}
                月<span>——</span>
                <StyledInput
                  lineInput
                  width={80}
                  name='toYear'
                  onChange={() => setFormDataWhenHandling()}
                />
                年
                <StyledInput
                  lineInput
                  width={80}
                  name='toMonth'
                  onChange={() => setFormDataWhenHandling()}
                />
                月
              </p>
            </FormItem>
          </Row>
        )}
        <Row>
          <FormItem>
            <p>
              <strong>* </strong>您是从哪里得知本次大赛？
            </p>
          </FormItem>
        </Row>
        <Row column rowGap='6px'>
          {whereToKnowThisContest.map((row, i) => (
            <Row key={i}>
              {row.map((item, i) => (
                <FormItem key={i}>
                  <section>
                    <Radio
                      value={item}
                      checked={whereToKnow === item}
                      onChange={(e) => {
                        setWhereToKnow(e)
                        setFormDataWhenHandling()
                      }}
                    >
                      <Span>{item}</Span>
                    </Radio>
                  </section>
                </FormItem>
              ))}
            </Row>
          ))}
          <Row>
            <FormItem>
              <p>其他：</p>
              <StyledInput
                lineInput
                name='other'
                width={100}
                onChange={() => setFormDataWhenHandling()}
              />
            </FormItem>
          </Row>
        </Row>

        <Row>
          <FormItem>
            <p>
              <strong>* </strong>贵司是否有采购或升级显卡计划？
            </p>
          </FormItem>
        </Row>
        <Row column rowGap='6px'>
          {hasPlanToBuyGPU.map((item, i) => (
            <FormItem key={i}>
              <section>
                <Radio
                  value={item}
                  checked={hasPlanToBuy === item}
                  onChange={(e) => setHasPlanToBuy(e)}
                >
                  <Span>{item}</Span>
                </Radio>
              </section>
            </FormItem>
          ))}
        </Row>
        <Row>
          <FormItem>
            <p>
              <strong>* </strong>采购计划否已经取得预算？
            </p>
          </FormItem>
        </Row>
        <Row column rowGap='6px'>
          <FormItem>
            <section>
              <Radio value='是' checked={hasBudget === '是'} onChange={(e) => setHasBudget(e)}>
                <Span>是</Span>
              </Radio>
            </section>
          </FormItem>
          <FormItem>
            <section>
              <Radio value='否' checked={hasBudget === '否'} onChange={(e) => setHasBudget(e)}>
                <Span>否</Span>
              </Radio>
            </section>
          </FormItem>
        </Row>
        <Row>
          <section>
            <p>
              <strong>* </strong>
              您是否有意愿注册并下载最新的NVIDIA Omniverse™? (若填写“愿意”则表示您愿意收到来自NVIDIA
            </p>
            <p>官方的Omniverse™下载邮件)</p>
          </section>
        </Row>
        <Row column rowGap='6px'>
          <FormItem>
            <section>
              <Radio
                value='是'
                checked={wannaRegister === '是'}
                onChange={(e) => setWannaRegister(e)}
              >
                <Span>是</Span>
              </Radio>
            </section>
          </FormItem>
          <FormItem>
            <section>
              <Radio
                value='否'
                checked={wannaRegister === '否'}
                onChange={(e) => setWannaRegister(e)}
              >
                <Span>否</Span>
              </Radio>
            </section>
          </FormItem>
        </Row>

        <Row>
          <section>
            <p>
              <strong>* </strong>
              您是否希望与 NVIDIA 技术专家或业务代表取得联系，以针对NVIDIA产品及技术提供更多帮助?
            </p>
          </section>
        </Row>
        <Row column rowGap='6px'>
          <FormItem>
            <section>
              <Radio
                value='是'
                checked={wannaConnect === '是'}
                onChange={(e) => setWannaConnect(e)}
              >
                <Span>是</Span>
              </Radio>
            </section>
          </FormItem>
          <FormItem>
            <section>
              <Radio
                value='否'
                checked={wannaConnect === '否'}
                onChange={(e) => setWannaConnect(e)}
              >
                <Span>否</Span>
              </Radio>
            </section>
          </FormItem>
        </Row>

        <Row>
          <section>
            <p>
              <strong>* </strong>
              您是否愿意戴尔科技销售与您取得联系，了解更多工作站产品及渲染方案?
            </p>
          </section>
        </Row>
        <Row column rowGap='6px'>
          <FormItem>
            <section>
              <Radio
                value='是'
                checked={connectWithDellSalers === '是'}
                onChange={(e) => setConnectWithDellSalers(e)}
              >
                <Span>是</Span>
              </Radio>
            </section>
          </FormItem>
          <FormItem>
            <section>
              <Radio
                value='否'
                checked={connectWithDellSalers === '否'}
                onChange={(e) => setConnectWithDellSalers(e)}
              >
                <Span>否</Span>
              </Radio>
            </section>
          </FormItem>
        </Row>

        <Row>
          <FormItem style={{ alignItems: 'flex-start' }}>
            <section style={{ width: 35 }}>
              <Checkbox
                checked={isSubscribe}
                onChange={(e) => {
                  setSubscribe(e)
                }}
              ></Checkbox>
            </section>
            <section style={{ padding: 0 }}>
              <p style={{ padding: 0, fontSize: 12 }}>
                请向我发送NVIDIA发布的有关企业的最新动态、 公告及其他内容。我可以随时取消订阅。
              </p>
            </section>
          </FormItem>
        </Row>
        <Row>
          <FormItem style={{ alignItems: 'flex-start' }}>
            <section style={{ width: 35 }}>
              <Checkbox checked={agreeSurvey} onChange={(e) => setAgreeSurvey(e)}></Checkbox>
            </section>
            <section style={{ padding: 0 }}>
              <p style={{ padding: 0, fontSize: 12 }}>
                本人知悉且同意 NVIDIA Corporation
                基于调研、活动组织的目的对本人的上述信息的收集和处理，并已经阅读并同意 NVIDIA
                <Link href='https://www.nvidia.cn/about-nvidia/privacy-policy/' target='_blank'>
                  隐私政策。
                </Link>
              </p>
            </section>
          </FormItem>
        </Row>
        <Row>
          <FormItem style={{ alignItems: 'flex-start' }}>
            <section style={{ width: 35 }}>
              <Checkbox
                checked={agreeInfoSubmit}
                onChange={(e) => setAgreeInfoSubmit(e)}
              ></Checkbox>
            </section>
            <section style={{ padding: 0 }}>
              <p style={{ padding: 0, fontSize: 12 }}>
                本人知悉且同意，因调研、活动组织的必须，以及相对应的 NVIDIA
                内部管理和系统操作的需要，上述信息会被传输到位于美国的 NVIDIA Corporation 按照符合{' '}
                <Link href='https://www.nvidia.cn/about-nvidia/privacy-policy/' target='_blank'>
                  NVIDIA 隐私政策
                </Link>
                的方式进行存储，您可以通过发送邮件至 privacy@nvidia.com
                进行联系以解决相关问题，实现可适用的数据保护法所规定的权利。
              </p>
            </section>
          </FormItem>
        </Row>

        <Row>
          <FormItem style={{ alignItems: 'flex-start' }}>
            <section style={{ width: 35 }}>
              <Checkbox checked={getAuthorize} onChange={(e) => setGetAuthorize(e)}></Checkbox>
            </section>
            <section style={{ padding: 0 }}>
              <p style={{ padding: 0, fontSize: 12 }}>
                所有参赛者向大赛提交的参赛作品需拥有独立完整的著作权，或已经取得著作权人的合法授权。主办方及合作方拥有对全部参赛作品进行展示、报道、宣传及用于市场活动的权利，主办方及合作方有权将用于市场活动的作品加入赛事水印，参赛者保留对其作品的著作权。获奖者将获邀参与由主办方及合作方举办的相关市场推广活动。作品一经提交，将视为参赛者同意并遵守比赛相关规定。
              </p>
            </section>
          </FormItem>
        </Row>

        <Row>
          {isExpired ? (
            <FormItem>
              <SubmitButton disabled>报名结束</SubmitButton>
            </FormItem>
          ) : (
            <FormItem>
              {(whereToKnow ? true : formData.other !== '') &&
              isSubscribe &&
              agreeSurvey &&
              agreeInfoSubmit &&
              getAuthorize &&
              hasPlanToBuy &&
              formFinish ? (
                <SubmitButton type='submit' disabled={submiting || disableSubmit}>
                  {submiting ? '提交中..' : '提交报名表'}
                </SubmitButton>
              ) : (
                <SubmitButton disabled>提交报名表</SubmitButton>
              )}
            </FormItem>
          )}
        </Row>
        <Row>
          <FormItem>
            <CodeWrapper>
              <StaticImage src='../../images/contest/code.jpeg' alt='code.png' />
            </CodeWrapper>
            <div>
              <p>扫码添加小助手微信，加入赛事交流群!</p>
              <p>你将在1-3个工作日收到报名邮件，可根据报名邮件提交作品。</p>
            </div>
          </FormItem>
        </Row>
      </FormContentWrapper>
    </FormWrapper>
  )
}

export default FormSubmit
