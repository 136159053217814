import styled from 'styled-components'
import { Media } from '../../style/theme'

export const TitleWrapper = styled.div`
  width: 301px;
  height: 61px;
  line-height: 61px;
  text-align: center;
  color: #fff;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom, #caa577, transparent) 10 10;
  border-bottom: none;
  backdrop-filter: blur(5px);
  background: linear-gradient(to bottom, rgba(195, 149, 118, 0.8) 0%, rgba(68, 45, 30, 0.1) 60%);
  margin-top: 194px;
  ${Media.phone`
    width: 250px;
    left: calc((100% - 150px) / 2);
    font-size: 18px;
    margin-top: 100px;
  `}
`

export const Title = styled.p`
  font-size: 30px;
  font-weight: bold;
  background: linear-gradient(to bottom, #fff, #caa577);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: ${({ top }) => (top ? top + 'px' : 'unset')};
  text-align: center;
  margin-bottom: ${({ pcBottom }) => pcBottom && pcBottom + 'px'};
  ${Media.phone`
    transform: scale(0.8);
    margin-bottom: ${({ mobileBottom }) => mobileBottom && mobileBottom + 'px'};
  `}
`

export const MainVideoWrapper = styled.div`
  width: 100%;
  height: 562px;
  max-width: 1003px;
  min-width: 600px;
  background: linear-gradient(0deg, rgba(68, 45, 30, 0.1) 0%, rgba(195, 149, 118, 0.8) 100%);
  margin-top: 58px;
  padding: 21px;
  > video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  ${Media.phone`
    max-width: 100%;
    min-width: 200px;
    height: 300px;
    padding: 10px;
  `}
`
