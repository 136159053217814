import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { DateboardWrapper, StyledButton } from './data-board.atoms'

const ddl = 1646927999000

const DateBoard = () => {
  return (
    <>
      {Math.ceil((ddl - new Date().getTime()) / 24 / 3600 / 1000) >= 0 && (
        <DateboardWrapper>
          <section>
            <p>{Math.ceil((ddl - new Date().getTime()) / 24 / 3600 / 1000)}</p>
            <p>DAYS</p>
          </section>
          <StyledButton href='#form-submit'>报名参加</StyledButton>
          <StaticImage src='../../images/contest/date-board.png' alt='date.png' />
        </DateboardWrapper>
      )}
    </>
  )
}

export default DateBoard
